import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import toast from '../../../../utils/toast';

import styles from './ProcessAPPBox.module.scss';

import {
  GET_CHECKLIST_DATA,
  RUN_OVERLAP,
  RUN_HIDRO_OVERLAP,
  RUN_CLASS_OVERLAP,
} from './query';

const OPTIONS = [
  'APP',
  'Class PPC',
  'CAR',
];

export default function ProcessAPPBox({
  aprtId,
  propertyId,
  anchorEl,
  refreshChecklist,
  onUpdate = () => {},
}) {
  const { data: checklistData, refetch: refetchChecklist } = useQuery(GET_CHECKLIST_DATA, {
    variables: {
      propertyId,
      idAprt: aprtId,
    },
  });
  const checklistItems = _.get(checklistData, 'aprtChecklistCf');
  const [isDisabled, setIsDisabled] = useState(false) 
  // const isDisabled = _.isEmpty(checklistItems) ||
  //   (_.size(checklistItems) === 1 && _.get(checklistItems, '[0].nome') === 'APP');

  const [runOverlap, {loading: runOverlapLoading}] = useMutation(RUN_OVERLAP, {
    onCompleted: (data) => {
      if (_.get(data, 'runOverlap.status') === true) {
        toast('Processamento realizado com sucesso!');
        onUpdate();
      }
    },
    onError: () => toast('Ocorreu um erro!', 'error')
  });


  useEffect(() => {
    if (_.isEmpty(checklistItems) || (_.size(checklistItems) === 1 && _.get(checklistItems, '[0].nome') === 'APP')){
      setIsDisabled(true)
    }else{
      setIsDisabled(false)
    }
  }, [checklistItems])

  useEffect(()=> {
    if(refreshChecklist){
      refetchChecklist()
    }
  }, [refreshChecklist])

  const [runHidroOverlap] = useMutation(RUN_HIDRO_OVERLAP, {
    onCompleted: (data) => {
      if (_.get(data, 'runHidroOverlap.status') === true) {
        toast('Processamento realizado com sucesso!');
        refetchChecklist({
          variables: {
            propertyId,
            idAprt: aprtId,
          }
        });
        onUpdate();
      }
    },
    onError: () => toast('Ocorreu um erro!', 'error')
  });

  const [runClassOverlap] = useMutation(RUN_CLASS_OVERLAP, {
    onCompleted: (data) => {
      if (_.get(data, 'runClassOverlap.status') === true) {
        toast('Processamento realizado com sucesso!');
        refetchChecklist({
          variables: {
            propertyId,
            idAprt: aprtId,
          }
        });
        onUpdate();
      }
    },
    onError: () => toast('Ocorreu um erro!', 'error')
  });

  const handleRunOverlap = () => {

    if (aprtId && propertyId) {
      runOverlap({
        variables: {
          idFazenda: propertyId,
          idAprt: aprtId
        }
      });
    } else {
      alert('Não é possível realizar o processamento.');
    }
  };

  const handleRunHidroOverlap = () => {
    if (aprtId && propertyId) {
      runHidroOverlap({
        variables: {
          idFazenda: propertyId,
          idAprt: aprtId
        }
      });
    } else {
      alert('Não é possível realizar o processamento.');
    }
  };

  const handleRunClassOverlap = () => {
    if (aprtId && propertyId) {
      runClassOverlap({
        variables: {
          idFazenda: propertyId,
          idAprt: aprtId
        }
      });
    } else {
      alert('Não é possível realizar o processamento.');
    }
  };

  return (
    <Popper open={ !!anchorEl } anchorEl={ anchorEl } placement="bottom-start" transition style={ { zIndex: 4000 } }>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={ 350 }>
          <Paper className={ styles.menuPapper }>
            <div className={ styles.primaryActionsWrapper }>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={ handleRunClassOverlap }
              >
                Processar PPC Class
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={ handleRunHidroOverlap }
              >
                Processar Hidrografia
              </Button>
            </div>
            <h2>Processar Código Florestal</h2>
            <ul className={ styles.categoryList }>
              { _.map(OPTIONS, (option) => {
                const isAvailable = !!_.find(checklistItems, { nome: option });

                return (
                  <li key={ `checklist-${ _.kebabCase(option) }` }>
                    { isAvailable ?
                      <CheckCircleIcon className={ styles.iconActive } /> :
                      <CheckCircleOutlinedIcon />
                    }
                    <span>{ option }</span>
                  </li>
                );
              }) }
            </ul>
            <div className={ styles.actionWrapper }>
              <Button
                size="small"
                variant="contained"
                color="primary"
                disabled={ isDisabled || runOverlapLoading }
                onClick={ handleRunOverlap }
              >
                Processar
              </Button>
            </div>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}
