import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import MessageIcon from '@material-ui/icons/Message';

import Dialog from '../../../../../../components/Dialog';

import ModerationActionForm from '../ModerationActionForm';

import toast from '../../../../../../utils/toast';

import styles from './ModerationDialog.module.scss';

import {
  GET_MODERATION_DATA,
  UPDATE_STATUS,
} from './query';

export default function ModerationDialog({
  activeProperties,
  loadingProperties,
  allLayers,
  isOpen,
  data,
  onClose = () => {},
}) {
  const activeLayerData = _.find(allLayers, { idCamada: _.get(data, 'layerId') });
  const [activeFormType, setActiveFormType] = useState(null);

  const [updateStatus] = useMutation(UPDATE_STATUS);

  const { data: moderationData, loading: loadingModerationData, refetch } = useQuery(GET_MODERATION_DATA, {
    variables: {
      moderationId: _.get(data, 'id')
    }
  });
  const moderationHistory = _.get(moderationData, 'moderacaoAndamento');

  const handleActiveFormTypeChange = (type) => {
    setActiveFormType(type);
  };

  const handleActiveFormClose = () => {
    setActiveFormType(null);
  };

  const externalStatus = _.get(data, 'externalStatus');

  const handleModerationSubmit = (params) => {

    const {
      description,
      status,
      files,
    } = params;

    let formData  = new FormData();

    formData.append('moderacao_andamento[id_moderacao]', _.get(data, 'id'));
    // TODO: Get the user ID
    formData.append('moderacao_andamento[id_user]', 1);

    if (!_.isEmpty(files)) {
      const file = _.first(files);

      formData.append('moderacao_andamento[file]', file);
      formData.append('moderacao_andamento[file_ref]', file.name);
    }

    if (description) {
      formData.append('moderacao_andamento[comentario]', description);
    }

    if (status) {
      updateStatus({
        variables: {
          id: _.get(data, 'id'),
          status
        }
      });
    }

    fetch(`/moderacao_andamento`, {
      method: 'POST',
      // TODO: Add Bearer token when API needs it
      // headers: {
      //   'Authorization': `Bearer ${ localStorage.getItem('token') }`
      // },
      body: formData
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (_.get(data, 'response') === true) {
          toast('Moderação atualizada com sucesso!');
        } else {
          toast('Ocorreu um erro.', 'error');
        }

        refetch({
          variables: {
            moderationId: _.get(data, 'id')
          }
        });
      });
  };

  const renderHistory = () => {
    if (loadingModerationData) {
      return (
        <span>Carregando...</span>
      );
    }

    if (_.isEmpty(moderationHistory)) {
      return (
        <div className={ styles.infoBox }>
          <p>Nenhum registro foi encontrado.</p>
        </div>
      );
    }

    return _.map(_.orderBy(moderationHistory, 'createdAt', 'desc'), (item) => {
      return (
        <div key={ `moderation-item-${ item.id }` } className={ styles.historyItem }>
          <div className={ styles.historyItemTop }>
            <div className={ styles.historyItemTopInfo }>
              <p className={ styles.infoItem }><span>Horário</span><b>{ moment(item.createdAt).format('DD/MM/YYYY - H:mm') }</b></p>
              {/* <p className={ styles.infoItem }><span>Status da propriedade</span><b>?</b></p> */}
            </div>
          </div>
          { item.comentario && <p className={ styles.infoItem }><span>Comentário</span><b>{ item.comentario }</b></p> }
          { item.fileRef &&
            <p className={ styles.infoItem }><span>Arquivo</span><b><a href={ item.url } target="_blank">{ item.fileRef }</a></b></p>
          }
        </div>
      );
    });
  };

  return (
    <Dialog
      hideActions
      title="Gerenciar Moderação"
      isOpen={ isOpen }
      onClose={ onClose }
      onConfirm={ onClose }
      size="md"
    >
      <div className={ styles.wrapper }>
        {/* <span
          className={ classnames(styles.statusTag, {
            [styles.statusTagYellow]: externalStatus === 'Bloqueada',
            [styles.statusTagGreen]: externalStatus === 'Ativa',
          }) }
        >
          { externalStatus }
        </span> */}
        { loadingProperties &&
          <div className={ styles.loadingWrapper }>
            <CircularProgress size={ 20 } color="secondary" />
          </div>
        }
        { activeProperties &&
          <Grid container spacing={ 0 } className={ styles.row }>
            <Grid item xs={ 6 } sm={ 3 }>
              <p className={ styles.infoItem }>
                <span>Categoria</span>
                <b>{ _.get(data, 'category') }</b>
              </p>
            </Grid>
            <Grid item xs={ 6 } sm={ 3 }>
              <p className={ styles.infoItem }>
                <span>Data de ocorrência</span>
                <b>{ moment(_.get(data, 'date')).format('DD/MM/YYYY') }</b>
              </p>
            </Grid>
            { _.map(_.keys(activeProperties), (key) => {
              return (
                <Grid item xs={ 6 } sm={ 3 } key={ `feature-${ _.get(data, 'id') }-${ key }` }>
                  <p className={ styles.infoItem }>
                    <span>{ key }</span>
                    <b>{ _.get(activeProperties, key) }</b>
                  </p>
                </Grid>
              );
            }) }
          </Grid>
        }
        <div className={ styles.sectionBlock }>
          <h3 className={ styles.sectionBlockTitle }>Ações</h3>
          <div className={ styles.sectionBlockActions }>
            <Button
              variant={ activeFormType === 'comment' ? 'contained' : 'outlined' }
              color="secondary"
              onClick={ handleActiveFormTypeChange.bind(this, 'comment') }
              startIcon={ <MessageIcon /> }
            >
              Adicionar comentário
            </Button>
            <Button
              variant={ activeFormType === 'status' ? 'contained' : 'outlined' }
              color="secondary"
              onClick={ handleActiveFormTypeChange.bind(this, 'status') }
              startIcon={ <EditIcon /> }
            >
              Alterar status
            </Button>
          </div>
          <ModerationActionForm
            activeType={ activeFormType }
            onClose={ handleActiveFormClose }
            onSubmit={ handleModerationSubmit }
          />
        </div>
        <div className={ styles.sectionBlock }>
          <h3 className={ styles.sectionBlockTitle }>Histórico</h3>
          { renderHistory() }
        </div>
      </div>
    </Dialog>
  );
}
