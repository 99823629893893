import React from 'react'
import _ from 'lodash'

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'

import {
  Query
} from 'react-apollo'

import {
  get,
  map,
  concat,
  defaults,
} from 'lodash'

import {
  GET_USER,
  GET_USER_ID,
} from './queries'

import redirect from './redirect'
import AppContext from '../context/app-context'

import LandingPage from '../../pages/LandingPage'

const routes = {
  all: [
    { exact: false, path: '/:propertyId', component: LandingPage },
  ],
  unauthenticated: [
  ],
  authenticated: [
  ],
}

const renderRoutes = ({ data, _loading }) => {
  let user = get(data, 'user', localStorage.getItem('token'))
  let routesToRender = user ? routes.authenticated : routes.unauthenticated
  routesToRender = concat(routesToRender, routes.all)

  return (
    <Router>
      <AppContext.Provider value={ user }>
        <Switch>
          {
            map(routesToRender, (route, i) => {
              let routeProps = defaults({}, route, { key: i, exact: true })
              if (route.redirect) {
                routeProps.component = redirect(route.redirect)
              }

              return <Route { ...routeProps } />
            })
          }
        </Switch>
      </AppContext.Provider>
    </Router>
  )
}

const Routes = () => {
  const urlToken = new URL(window.location.href).searchParams.get('t');
  const target = new URL(window.location.href).searchParams.get('target');
  const token = localStorage.getItem('token');

  if (urlToken) {
    localStorage.setItem('token', urlToken);

    window.location.href = new URL(target, window.location.href);
  } else if (token) {
    return (
      <Query query={ GET_USER_ID }>
        { ({ data, _loading }) => {
          return (
            <Query query={ GET_USER } variables={{ id: get(data, 'currentUserId', 0) }}>
              { renderRoutes }
            </Query>
          )
        } }
      </Query>
    );
  } else {
    console.log(new URL('/#/auth/signin', process.env.ALIANCA_MAIN_URI))
    window.location.href = new URL('/#/auth/signin', process.env.ALIANCA_MAIN_URI);
  }
};

export default Routes
