import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import * as turf from '@turf/turf';

import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import fetchLayerPointData from '../../../../helpers/fetchLayerPointData';

import mapLayerIdToProps from '../../../../data/mapLayerIdToProps';

import {
  GET_BASE_DATA,
} from './query';

import styles from './PointInfoBox.module.scss';

const EXTERNAL_CONTENT_HEIGHT = 207;

export default function PointInfoBox({
  baseLayers,
  data,
  layerGroups,
  propertyId,
  onClose = () => {},
}) {
  const isOpen = !!data;
  const bboxString = _.get(data, 'bboxString');
  const coordinates = _.get(data, 'coordinates');
  const contentMaxHeight = window.innerHeight - EXTERNAL_CONTENT_HEIGHT;
  const [activePanels, setActivePanels] = useState([]);
  const [layersData, setLayersData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data: baseData } = useQuery(GET_BASE_DATA);

  const activeLayers = _.get(baseData, 'app.activeLayers');
  const activeBaseLayers = _.get(baseData, 'app.activeBaseLayers');
  const activeLayersIds = [...(_.map(activeLayers, 'id')), ...activeBaseLayers];

  useEffect(() => {
    if (bboxString) {
      setActivePanels(activeLayersIds);
      setIsLoading(true);

      // TODO: Remove this filter when layers get back to work
      const filteredActiveLayersIds = _.filter(activeLayersIds, (id) => {
        return !_.includes([
          'ppc:v_carbon_map',
          'ppc:carbon_map',
          'ppc:v_app_declividade',
          'ppc:v_app_elevacao',
          'ppc:aprt_area_umida',
          'ppc:aprt_nascentes',
        ], id);
      });

      const requests = _.map(filteredActiveLayersIds, (layerId) => {
        return fetchLayerPointData(layerId, bboxString);
      });

      Promise.all(requests)
        .then((values) => {
          const parsedData = _.reduce(filteredActiveLayersIds, (obj, layerId, index) => {
            const isGlobal = _.includes(activeBaseLayers, layerId);
            const value = values[index];
            const features = _.get(value, 'features');
            const point = turf.point([coordinates[1], coordinates[0]]);
            const feature = _.filter(features, (feature) => {
              if (!isGlobal) {
                return turf.booleanPointInPolygon(point, feature);
              }

              return true;
            });
            const properties = _.get(_.first(feature), 'properties');

            obj[layerId] = properties;
            return obj;
          }, {});

          setLayersData(parsedData);
          setIsLoading(false);
        });
    }
  }, [bboxString]);

  const panelIsOpen = (id) => _.includes(activePanels, id);

  const handlePanelChange = (id) => {
    let dataClone = _.clone(activePanels);

    if (panelIsOpen(id)) {
      dataClone = _.filter(dataClone, (item) => item !== id);
    } else {
      dataClone = _.concat(dataClone, id);
    }

    setActivePanels(dataClone);
  };

  const handleClose = () => {
    setLayersData(null);
    onClose();
  };

  const parsedLayers = _(layerGroups)
    .map((layerGroup) => {
      return _.map(layerGroup.options, (layer) => {
        const layerData = _.get(layersData, layer.id);

        if (_.includes(activeLayersIds, layer.id) && layerData) {
          return {
            ...layer,
            groupId: layerGroup.id,
            groupTitle: layerGroup.title,
            groupIcon: layerGroup.icon,
          };
        } else {
          return null;
        }
      });
    })
    .flattenDeep()
    .compact()
    .value();

  const parsedActiveBaseLayers = _.map(activeBaseLayers, (layerId) => {
    const baseLayerData = _.find(baseLayers, { id: layerId });

    return {
      global: true,
      id: layerId,
      label: _.get(baseLayerData, 'label'),
    };
  });

  const layers = [...parsedLayers, ...parsedActiveBaseLayers];

  const renderInfoList = (layerId) => {
    // Temporarily render all props
    // const props = mapLayerIdToProps[layerId];
    const layerData = _.get(layersData, layerId);

    if (!layerData) { return null; }

    return (
      <Grid container spacing={ 0 }>
        { _.map(_.keys(layerData), (key) => {
          return (
            <Grid item xs={ 12 } sm={ 6 } key={ `info-item-${ layerId }-${ key }` }>
              <p className={ styles.infoItem }>
                <span>{ key }</span>
                <b>{ _.get(layerData, key) }</b>
              </p>
            </Grid>
          );
        }) }
      </Grid>
    );

    // return (
    //   <Grid container spacing={ 0 }>
    //     { _.map(props, ({ key, label }) => {
    //       return (
    //         <Grid item xs={ 12 } sm={ 6 } key={ `info-item-${ layerId }-${ key }` }>
    //           <p className={ styles.infoItem }>
    //             <span>{ label }</span>
    //             <b>{ _.get(layerData, key) }</b>
    //           </p>
    //         </Grid>
    //       );
    //     }) }
    //   </Grid>
    // );
  };

  const renderContent = () => {
    return _.map(layers, (layer) => {
      return (
        <Accordion
          square
          key={ `${ layer.groupId }-${ layer.id }` }
          expanded={ panelIsOpen(layer.id) }
          onChange={ handlePanelChange.bind(this, layer.id) }
        >
          <AccordionSummary>
            <h3 className={ styles.accordionTitle }>
              { !layer.global &&
                <img className={ styles.accordionTitleIcon } src={ layer.groupIcon } />
              }
              <span className={ styles.accordionTitleText }>
                { !layer.global && <small>{ layer.groupTitle }</small> }
                <b>{ layer.label }</b>
                </span>
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            { renderInfoList(layer.id) }
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <Fade in={ isOpen }>
      <Paper className={ styles.paper }>
        <h2 className={ styles.title }>
          <span className={ styles.titleText }>Informações do Ponto</span>
          <Tooltip title="Fechar">
            <button className={ styles.titleButton } onClick={ handleClose }>
              <CloseIcon />
            </button>
          </Tooltip>
        </h2>
        <div className={ styles.contentWrapper } style={ { maxHeight: contentMaxHeight } }>
          { !isLoading && !_.isEmpty(layers) && renderContent() }
          { !isLoading && _.isEmpty(layers) &&
            <p className={ styles.infoText }>Adicione camadas para visualizar as informações.</p>
          }
          { isLoading &&
            <div className={ styles.loadingWrapper }>
              <CircularProgress color="secondary" size={ 20 } />
            </div>
          }
        </div>
      </Paper>
    </Fade>
  );
}
