import { gql } from 'graphql.macro';

export const UPDATE_REGISTRY_ITEMS = gql`
  mutation UpdateRegistryItems(
    $idFazenda: Int,
    $idAprt: Int,
    $codImoveis: JSON,
    $idCamada: Int,
  ) {
    aprtRegistro(
      idFazenda: $idFazenda,
      idAprt: $idAprt,
      idCamada: $idCamada,
      codImoveis: $codImoveis,
    ){
      response
    }
  }
`;

export const LOAD_REGISTRY_ITEMS = gql`
  query LoadRegistryItems(
    $idFazenda: Int!,
    $idAprt: Int!,
    $idCamada: Int!,
  ) {
    aprtRegistroQuery(
      idFazenda: $idFazenda,
      idAprt: $idAprt,
      idCamada: $idCamada,
    ) {
      codImovel
      id
      idAprt
      idCamada
      idFazenda
      updatedAt
    }
  }
`;

export const ADD_REGISTRY_ITEMS = gql`
  mutation AddRegistryItems(
    $idFazenda: Int,
    $idAprt: Int,
    $values: JSON,
    $idCamada: Int,
  ) {
    insertAprtRegistro(
      idFazenda: $idFazenda,
      idAprt: $idAprt,
      idCamada: $idCamada,
      valores: $values,
    ) {
      response
      status
    }
  }
`;

export const REMOVE_REGISTRY_ITEMS = gql`
  mutation RemoveRegistryItems(
    $idFazenda: Int,
    $idAprt: Int,
    $values: JSON,
    $idCamada: Int,
  ) {
    deleteAprtRegistro(
      idFazenda: $idFazenda,
      idAprt: $idAprt,
      idCamada: $idCamada,
      valores: $values,
    ) {
      response
      status
    }
  }
`;
