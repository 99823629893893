import React from 'react';
import _ from 'lodash';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import CloseIcon from '@material-ui/icons/Close';
import DialogUI from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

import styles from './MapPhotosGallery.module.scss'

export default function MapPhotosGallery({
  isOpen,
  data = [],
  onClose = () => {},
}) {
  const parsedPhotos = _.map(data, (item) => {
    const url = _.get(item, 'photoUrl');

    return {
      original: url,
      thumbnail: url,
    };
  });

  return (
    <DialogUI
      open={ isOpen }
      onClose={ onClose }
      classes={ {
        paper: styles.dialogWrapper
      } }
      fullWidth
      maxWidth="sm"
    >
      <div className={ styles.wrapper }>
        <header>
          <h2 className={ styles.title }>
            Galeria de Fotos
          </h2>
          <IconButton
            size="small"
            className={ styles.closeButton }
            onClick={ onClose }
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </header>
        <div className={ styles.content }>
          <ImageGallery items={ parsedPhotos } />
        </div>
      </div>
    </DialogUI>
  );
}
