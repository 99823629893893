export default [
  {
    id: 'location',
    title: 'Localização',
    layers: ['ppc:aprt_limite', 'ppc:aprt_sede']
  },
  {
    id: 'biome',
    title: 'Biomas',
    layers: ['ppc:aprt_limite', 'ppc:v_limite_biomas']
  },
  {
    id: 'land-use',
    title: 'Uso e Cobertura do Solo',
    layers: ['ppc:aprt_limite', 'ppc:aprt_sede', 'ppc:aprt_class', 'ppc:aprt_drenagem', 'ppc:aprt_nascente', 'ppc:aprt_lagoa', 'ppc:aprt_app'],
    conditionalLayers: [
      ['ppc:aprt_app_uso_cf', 'ppc:aprt_app_uso_class_old', 'ppc:aprt_app_uso_mapbiomas_2019']
    ]
  },
  {
    id: 'soil-erosion',
    title: 'Erosão e Conservação do Solo',
    layers: ['ppc:aprt_limite', 'ppc:aprt_sede', 'ppc:aprt_drenagem_pol', 'ppc:aprt_drenagem', 'ppc:aprt_nascente', 'ppc:aprt_erosao_controle', 'ppc:aprt_erosao', 'ppc:aprt_lagoa']
  },
  {
    id: 'fire',
    title: 'Queimadas',
    layers: ['ppc:aprt_limite', 'ppc:aprt_sede', 'ppc:v_focos_calor']
  },
  {
    id: 'water',
    title: 'Análise de Água',
    layers: ['ppc:aprt_limite', 'ppc:aprt_sede', 'ppc:aprt_drenagem_pol', 'ppc:aprt_drenagem', 'ppc:aprt_nascente', 'ppc:aprt_lagoa', 'ppc:aprt_analise_agua']
  },
  {
    id: 'protected-area',
    title: 'TI UC',
    layers: ['ppc:aprt_limite', 'ppc:v_limite_tis', 'ppc:v_limite_ucs']
  },
  {
    id: 'cas',
    title: 'CAS',
    layers: ['ppc:aprt_limite', 'ppc:aprt_sede', 'ppc:aprt_drenagem_pol', 'ppc:aprt_drenagem', 'ppc:aprt_nascente', 'ppc:aprt_lagoa', 'ppc:aprt_app']
  },
];
