import { gql } from 'graphql.macro';

export const GET_SHAPEFILE_STATUS = gql`
  query GetShapefileStatus(
    $id: Int!,
  ) {
    statusShapeFile(id: $id) {
      idAprt
      idFazenda
      aprtTipo
      response
      status
      checklistStatus
    }
  }
`;
