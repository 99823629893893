import { gql } from 'graphql.macro';

export const GET_APRT_DATA = gql`
  query GetAprtData(
    $propertyId: Int!,
  ) {
    listAprts(idFazenda: $propertyId){
      idFazenda
      idAprt
      cdMun
      areaHa
      createdAt
    }
  }
`;

export const GET_BASE_DATA = gql`
  query GetBaseData(
    $propertyId: Int!,
    $aprtId: Int!,
  ) {
    camadas {
      idCamada
      ano
      fonte
      nome
      tabela
      tema
      global
      cruzamento
      tipo
    }

    camadasDisp(
      idFazenda: $propertyId,
      idAprt: $aprtId
    ){
      ano
      fonte
      nome
      tabela
      tema
      global
      cruzamento
      tipo
    }

    app @client {
      activeBaseLayers
    }
  }
`;

export const GET_MODERATION_DATA = gql`
  query GetModerationData(
    $propertyId: Int!,
    $aprtId: Int!,
  ) {
    moderacaoResultadoCruzamentos(idFazenda: $propertyId, idAprt: $aprtId) {
      id
      idFeicao
      idAprt
      idFazenda
      updatedAt
      valor
      status
      nome
      unidade
      createdAt
      idCamada
      dataOcorrencia
    }
  }
`;

export const RUN_OVERLAP = gql`
  mutation RunOverlap(
    $idFazenda: Int,
    $idAprt: Int,
  ) {
    runOverlap(
      idFazenda: $idFazenda,
      idAprt: $idAprt,
    ){
      response
      status
    }
  }
`;


export const GET_FARM_DATA = gql`
  query GetFarmData(
    $propertyId: Int!,
  ) {
    farm(
      id: $propertyId
    ){
      id
      name
      avatar
      responsible_name
      responsible_office
      responsible_phone
      responsible_email
      active
      latitude
      longitude
      address{
        address
        city{
          name
        }
        state{
          name
          initials
        }
        country{
          name
        }
      }
      image{
        latitude
        longitude
        info_image
        image_date
      }
    }
  }
`;
