import { gql } from 'graphql.macro';

export const GET_FARMS = gql`
  query GetFarms(
    $page: Int,
    $where: String
  ){
    farmersList(
      perPage:10,
      page: $page,
      ordenation:[{column: "name", direction:"ASC"}],
      filters: [$where]
    ){
      data{
        id
        name
      }
      total
      has_more_pages
      from
      last_page
    }
  }
`;