import iconApp from '../assets/icons/icon-app.png';
import iconAprt from '../assets/icons/icon-aprt.png';
import iconCarbono from '../assets/icons/icon-carbono.png';
import iconErosao from '../assets/icons/icon-erosao.png';
import iconHidrografia from '../assets/icons/icon-hidrografia.png';
import iconUsoDoSolo from '../assets/icons/icon-uso-do-solo.png';

import iconHouse from '../assets/icons/house.png';
import iconWarning from '../assets/icons/warning.png';
import iconFlame from '../assets/icons/flame.png';
import iconDeforestation from '../assets/icons/deforestation.png';

const mapLayerThemeToIcon = {
  'APRT': iconAprt,
  'Fundiário': iconApp,
  'Carbono': iconCarbono,
  'Código Florestal': iconApp,
  'Desmatamento': iconDeforestation,
  'Fundiário': iconHouse,
  'Incêndio': iconFlame,
  'Infração': iconWarning,
  'Meio Fisíco': iconUsoDoSolo,
};

export default function generateLayersGroups(layers) {
  const parsedLayersGroups = _(layers)
    .filter(({ cruzamento, global, tabela }) => {
      return cruzamento || (!cruzamento && !global) || tabela === 'aprt_limite';
    })
    .groupBy('tema')
    .map((data, key) => {
      return {
        id: key,
        title: key,
        icon: mapLayerThemeToIcon[key] || iconApp,
        options: _.map(data, (layer) => {
          const layerId = (layer.cruzamento && layer.global) ? `ppc:v_${ layer.tabela }` : `ppc:${ layer.tabela }`;

          return { id: layerId, label: layer.nome }
        })
      };
    })
    .map((group) => {
      if (group.title === 'APRT') {
        return {
          ...group,
          options: [
            { id: 'ppc:aprt_limite', label: 'APRT' },
            ...(group.options)
          ]
        }
      } else {
        return group;
      }
    })
    .orderBy((group) => group.title === 'APRT', 'desc')
    .value();

  return parsedLayersGroups;
}
