import React, { useState } from 'react';
import _ from 'lodash';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';

import Dialog from '../../../../components/Dialog';
import OptionsList from '../../../../components/OptionsList';

import SearchFarmDialog from './components/SearchFarmDialog';

import styles from './ActiveFarmControl.module.scss';

export default function ActiveFarmControl({ data }) {
  const [modal, setModal] = useState(null);
  const [activeFarm, setActiveFarm] = useState(null);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (!data) {
    return null;
  }

  return (
    <div className={ styles.wrapper }>
      <Tooltip title="Voltar">
        <a
          className={ styles.actionButton }
          href={ `${ process.env.ALIANCA_MAIN_URI }/farms-view/${ _.get(data, 'farm.id') }` }
        >
          <ArrowBackIcon />
        </a>
      </Tooltip>
      <div className={ styles.infoWrapper }>
        {/* <img className={ styles.infoImage } src={ _.get(data, 'image') } /> */}
        <img className={ styles.infoImage } src={ _.get(data, 'farm.avatar') } />
        <div className={ styles.infoText }>
          {/* <h1 className={ styles.infoTextTitle }>{ _.get(data, 'name') }</h1> */}
          <h1 className={ styles.infoTextTitle }>{ _.get(data, 'farm.name') }</h1>
          {/* <h2 className={ styles.infoTextSubtitle }>{ _.get(data, 'city') } / { _.get(data, 'state') }</h2> */}
          <h2 className={ styles.infoTextSubtitle }>{ 
            _.get(data, 'farm.address.city.name') } / { _.get(data, 'farm.address.state.initials') }
          </h2>
        </div>
      </div>
      <Tooltip title="Trocar propriedade">
        <button className={ styles.actionButton } onClick={ toggleModal }>
          <AutorenewIcon />
        </button>
      </Tooltip>
      <SearchFarmDialog
        isOpen={ !!modal }
        onClose={ toggleModal }
      />
    </div>
  );
}
