import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DialogUI from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

import styles from './Dialog.module.scss';

export default function Dialog({
  children,
  className,
  isLoadingActions = false,
  hideActions = false,
  isOpen,
  title,
  size = 'xs',
  onClose = () => {},
  onConfirm = () => {},
}) {
  return (
    <DialogUI
      open={ isOpen }
      onClose={ onClose }
      classes={ {
        paper: classnames(styles.dialogWrapper, className)
      } }
      maxWidth={ size }
    >
      <div className={ styles.wrapper }>
        <header>
          <h2 className={ styles.title }>
            { title }
          </h2>
          <IconButton
            size="small"
            className={ styles.closeButton }
            onClick={ onClose }
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </header>
        <div className={ styles.content }>
          { children }
        </div>
        { isLoadingActions &&
          <div className={ styles.loadingActionsWrapper }>
            <CircularProgress size={ 20 } color="secondary" />
          </div>
        }
        { !hideActions && !isLoadingActions &&
          <div className={ styles.actions }>
            <Button
              size="small"
              variant="contained"
              onClick={ onClose }
            >
              Cancelar
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={ onConfirm }
            >
              Confirmar
            </Button>
          </div>
        }
      </div>
    </DialogUI>
  );
}
