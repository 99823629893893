import React from "react";
import Dialog from '../../../../../../components/Dialog';
import styles from './exportGeo.module.scss';
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';

export default function ExportGeo({isOpen, onClose, aprt}) {
    return (
        <Dialog title="Exportar como:" isOpen={isOpen} onClose={onClose} hideActions>
            <Box className={styles.boxStyle}>Shapefile 
                <Button
                href={`http://production-geoserver.produzindocerto.com.br/geoserver/ppc/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=[camada]&outputFormat=SHAPE-ZIP&CQL_FILTER=id_fazenda= and id_aprt=${aprt}`}
                target="_blank"
                size="small"
                variant="contained"
                color="primary">
                    Exportar
                </Button>
            </Box>
            <Box className={styles.boxStyle}>GeoJson 
                <Button
                href={`http://production-geoserver.produzindocerto.com.br/geoserver/ppc/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=[camada]&outputFormat=SHAPE-ZIP&CQL_FILTER=id_fazenda= and id_aprt=${aprt}`}
                target="_blank"
                size="small"
                variant="contained"
                color="primary">
                    Exportar
                </Button>
            </Box>
            <Box className={styles.boxStyle}>KML 
                <Button
                href={`http://production-geoserver.produzindocerto.com.br/geoserver/ppc/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=[camada]&outputFormat=kml&CQL_FILTER=id_fazenda=andid_aprt=${aprt}`}
                target="_blank"
                size="small"
                variant="contained"
                color="primary">
                    Exportar
                </Button>
            </Box>
        </Dialog>
    )
}