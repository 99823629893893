import { gql } from 'graphql.macro';

export const GET_CHECKLIST_DATA = gql`
  query GetChecklistData(
    $propertyId: Int!,
    $idAprt: Int!,
  ) {
    aprtChecklistCf(
      idFazenda: $propertyId,
      idAprt: $idAprt,
    ) {
      idCamada
      idFazenda
      nome
    }
  }
`;

export const RUN_OVERLAP = gql`
  mutation RunOverlap(
    $idFazenda: Int,
    $idAprt: Int,
  ) {
    runOverlap(
      idFazenda: $idFazenda,
      idAprt: $idAprt,
    ){
      response
      status
    }
  }
`;

export const RUN_HIDRO_OVERLAP = gql`
  mutation RunHidroOverlap(
    $idFazenda: Int,
    $idAprt: Int,
  ) {
    runHidroOverlap(
      idFazenda: $idFazenda,
      idAprt: $idAprt,
    ){
      response
      status
    }
  }
`;

export const RUN_CLASS_OVERLAP = gql`
  mutation RunClassOverlap(
    $idFazenda: Int,
    $idAprt: Int,
  ) {
    runClassOverlap(
      idFazenda: $idFazenda,
      idAprt: $idAprt,
    ){
      response
      status
    }
  }
`;
