import _ from 'lodash';

export default function fetchPropertyLayerData(layerId, propertyId, aprtId) {
  const geoserverBaseUrl = `${ process.env.GEOSERVER_URL }ppc/wfs?`;
  const wfsParams = [
    'service=WFS',
    'version=2.0.0',
    'request=GetFeature',
    `typeNames=${ layerId }`,
    'outputFormat=application/json',
    'srsName=EPSG:4326',
    `cql_filter=id_fazenda=${ propertyId } and id_aprt=${ aprtId }`,
  ];

  return fetch(`${ geoserverBaseUrl }${ wfsParams.join('&') }`)
    .then((resp) => resp.json());
}
