import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import Dialog from '../../../../../../components/Dialog';

import styles from './LayersInfoDialog.module.scss';
import _ from 'lodash';

export default function LayersInfoDialog({
  isOpen,
  activeLayersIds,
  layerGroups,
  onClose = () => {},
}) {
  const [activePanels, setActivePanels] = useState([]);

  const panelIsOpen = (id) => _.includes(activePanels, id);

  const handlePanelChange = (id) => {
    let dataClone = _.clone(activePanels);

    if (panelIsOpen(id)) {
      dataClone = _.filter(dataClone, (item) => item !== id);
    } else {
      dataClone = _.concat(dataClone, id);
    }

    setActivePanels(dataClone);
  };

  const parsedLayers = _(layerGroups)
    .map((layerGroup) => {
      return _.map(layerGroup.options, (layer) => {
        if (_.includes(activeLayersIds, layer.id)) {
          return {
            ...layer,
            groupId: layerGroup.id,
            groupTitle: layerGroup.title,
            groupIcon: layerGroup.icon,
          };
        } else {
          return null;
        }
      });
    })
    .flattenDeep()
    .compact()
    .value();

  return (
    <Dialog
      hideActions
      isOpen={ isOpen }
      title="Informações das Camadas"
      onClose={ onClose }
      size="xs"
      className={ styles.dialogWrapper }
    >
      <div className={ styles.contentWrapper }>
        { _.map(parsedLayers, (layer) => {
          return (
            <Accordion
              square
              key={ `${ layer.groupId }-${ layer.id }` }
              expanded={ panelIsOpen(layer.id) }
              onChange={ handlePanelChange.bind(this, layer.id) }
            >
              <AccordionSummary>
                <h3 className={ styles.accordionTitle }>
                  <img className={ styles.accordionTitleIcon } src={ layer.groupIcon } />
                  <span className={ styles.accordionTitleText }>
                    <small>{ layer.groupTitle }</small>
                    <b>{ layer.label }</b>
                    </span>
                </h3>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={ 1 }>
                  <Grid item xs={ 12 } sm={ 6 }>
                    <p className={ styles.infoItem }><span>Informação 01</span><b>Valor 01</b></p>
                  </Grid>
                  <Grid item xs={ 12 } sm={ 6 }>
                    <p className={ styles.infoItem }><span>Informação 02</span><b>Valor 02</b></p>
                  </Grid>
                  <Grid item xs={ 12 }>
                    <p className={ styles.infoItem }><span>Informação 03</span><b>Valor 03</b></p>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        }) }
        { _.isEmpty(parsedLayers) &&
          <p className={ styles.infoText }>Adicione camadas para visualizar as informações.</p>
        }
      </div>
    </Dialog>
  );
}
