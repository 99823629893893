export default function fetchLayerPointData(layerId, bboxString) {
  const geoserverBaseUrl = `${ process.env.GEOSERVER_URL }ppc/ows?`;
  const wfsParams = [
    'service=WFS',
    'version=2.0.0',
    'request=GetFeature',
    `typeNames=${ layerId }`,
    'count=20',
    'outputFormat=application/json',
    'srsName=EPSG:4326',
    `bbox=${ bboxString },EPSG:4326`,
  ];

  return fetch(`${ geoserverBaseUrl }${ wfsParams.join('&') }`)
    .then((resp) => resp.json());
}
