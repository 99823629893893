import Popper from '@material-ui/core/Popper'; 
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import React from 'react';
import styles from './PoperLayer.module.scss';

export default function PoperLayer({aprtMenuAnchorEl, expanded, item, aprtId, propertyId,}) {
    return(
    <Popper open={ !!aprtMenuAnchorEl && expanded } anchorEl={ aprtMenuAnchorEl } placement="right-start" transition style={ { zIndex: 4000 } }>
        <Paper className={ styles.menuPapper }>
            <h2 className={ styles.label }>Exportar APRT: </h2>
                <Button
                href={`https://staging-geoserver.produzindocerto.com.br/geoserver/ppc/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${item.id}&outputFormat=SHAPE-ZIP&CQL_FILTER=id_fazenda=${propertyId} and id_aprt=${aprtId}`}
                target="_blank"
                size="small"
                variant="text"
                className={ styles.button }>
                    Shapefile
                </Button>

                <Button
                href={`https://staging-geoserver.produzindocerto.com.br/geoserver/ppc/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${item.id}&outputFormat=application/json&CQL_FILTER=id_fazenda=${propertyId} and id_aprt=${aprtId}`}
                target="_blank"
                size="small"
                variant="text"
                className={ styles.button }>
                    GeoJson
                </Button>

                <Button
                href={`https://staging-geoserver.produzindocerto.com.br/geoserver/ppc/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${item.id}&outputFormat=kml&CQL_FILTER=id_fazenda=${propertyId} and id_aprt=${aprtId}`}
                target="_blank"
                size="small"
                variant="text"
                className={ styles.button }>
                    KML
                </Button>
        </Paper>
    </Popper>
    )
        
}