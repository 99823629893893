import _ from 'lodash';

const mapTypeToIndex = {
  'point': 4,
  'polyline': 3,
  'polygon': 2,
  'raster': 1,
};

export default function parseBaseLayers(layers) {
  const filteredLayers = _.filter(layers, 'global');

  return _.map(filteredLayers, (layer, index) => {
    return {
      id: `ppc:${ layer.tabela }`,
      label: layer.nome,
      props: {
        url: `${ process.env.GEOSERVER_URL }gwc/service/wmts`,
        style: 'normal',
        transparent: true,
        format: 'image/png',
        layer: `ppc:${ layer.tabela }`,
        tilematrixSet: 'EPSG:900913',
        opacity: 0.8,
        zIndex: 200 + ((mapTypeToIndex[layer.tipo] || 0) * 10) + index
      }
    };
  });
}
