import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DeleteIcon from '@material-ui/icons/Delete';
import Fade from '@material-ui/core/Fade';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';

import Dialog from '../../../../../../components/Dialog';

import toast from '../../../../../../utils/toast';

import styles from './APRTActionsMenu.module.scss';

import {
  REMOVE_APRT,
} from './query';

export default function APRTActionsMenu({
  aprtList = [],
  aprtId,
  propertyId,
  onAprtRemove = () => {},
}) {
  
  const [aprtMenuAnchorEl, setAprtMenuAnchorEl] = useState(null);
  const [showAprtRemoveDialog, setShowAprtRemoveDialog] = useState(false);

  const [removeAprt] = useMutation(REMOVE_APRT, {
    onCompleted: (data) => {
      if (_.get(data, 'deleteShps.status') === true) {
        setShowAprtRemoveDialog(false);
        toast('APRT excluída com sucesso!');
        onAprtRemove();
      }
    },
    onError: () => toast('Ocorreu um erro!', 'error')
  });

  const activeAprt = _.find(aprtList, { idAprt: aprtId });
  const activeAprtArea =  _.get(activeAprt, 'areaHa');
  const activeAprtLabel = `${ _.get(activeAprt, 'idAprt') } - ${ moment(_.get(activeAprt, 'createdAt'), 'YYYY-MM-DD').format('DD/MM/YYYY') } - ${ activeAprtArea ? activeAprtArea.toFixed(2) : '- ' }ha`;

  const toggleAPRTMenu = (event) => {
    if (!!aprtMenuAnchorEl) {
      setAprtMenuAnchorEl(null);
    } else {
      setAprtMenuAnchorEl(event.currentTarget);
    }
  };

  const toggleAPRTRemoveDialog = () => {
    if (!!aprtMenuAnchorEl) {
      setAprtMenuAnchorEl(null);
    }

    setShowAprtRemoveDialog(!showAprtRemoveDialog);
  };

  const handleClickAway = () => {
    if (!!aprtMenuAnchorEl) {
      setAprtMenuAnchorEl(null);
    }
  };

  const handleAPRTRemove = () => {
    removeAprt({
      variables: {
        idFazenda: propertyId,
        idAprt: aprtId
      }
    });
  };

  return (
    <Fragment>
      <Tooltip title="Ações" placement="bottom">
        <button className={ styles.titleButton } onClick={ toggleAPRTMenu }>
          <MoreVertIcon />
        </button>
      </Tooltip>
        <Popper open={ !!aprtMenuAnchorEl } anchorEl={ aprtMenuAnchorEl } placement="right-start" transition style={ { zIndex: 4000 } }>
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={ handleClickAway }>
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={ styles.menuPapper }>
                  { aprtId &&
                    <button className={ styles.menuLink } onClick={ toggleAPRTRemoveDialog }>
                      <DeleteIcon />
                      <span className={ styles.menuLinkTextContent }>
                        <span>Remover APRT selecionada</span>
                        <span>{ activeAprtLabel }</span>
                      </span>
                    </button>
                  }
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      <Dialog
        title="Confirme a exclusão da APRT"
        isOpen={ showAprtRemoveDialog }
        onClose={ toggleAPRTRemoveDialog }
        onConfirm={ handleAPRTRemove }
        size="sm"
      >
        <p className={ styles.descriptionText }>Tem certeza que deseja remover a APRT: <b>{ activeAprtLabel }</b> ?</p>
      </Dialog>
    </Fragment>
  );
}
