import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';

import ModerationDialog from './components/ModerationDialog';
import ModerationListFilters from './components/ModerationListFilters';

import useWindowSize from '../../../../hooks/useWindowSize';

import styles from './ModerationBox.module.scss';

function parseData(items) {
  return _.map(items, (item) => {
    return {
      id: _.get(item, 'id'),
      date: _.get(item, 'dataOcorrencia'),
      category: _.get(item, 'nome'),
      layerId: _.get(item, 'idCamada'),
      pathId: _.get(item, 'idFeicao'),
      status: _.get(item, 'status'),
      externalStatus: _.sample(['Ativa', 'Bloqueada'])
    };
  });
}

export default function ModerationBox({
  activeItem,
  activeFeature,
  loadingFeature,
  allLayers,
  data,
  isOpen,
  isOnCorner,
  onActiveItemChange = () => {},
  onClose = () => {},
}) {
  const size = useWindowSize();
  const [showDialog, setShowDialog] = useState(false);
  const [mode, setMode] = useState('list');
  const [status, setStatus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [baseItems, setBaseItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const activeProperties = _.get(activeFeature, 'properties');

  useEffect(() => {
    if (data) {
      const parsedData = parseData(data);
      const categories = _.sortBy(_.uniq(_.map(parsedData, 'category')));
      const status = _.sortBy(_.uniq(_.map(parsedData, 'status')));
      let filteredItems = _.cloneDeep(parsedData);
      filteredItems = _.filter(filteredItems, { status: 'pendente' });

      setBaseItems(parsedData);
      setFilteredItems(filteredItems);
      setCategories(categories);
      setStatus(status);
    }
  }, [data]);

  useEffect(() => {
    if (isOpen) {
      setMode('list');
      onActiveItemChange(null);
    }
  }, [isOpen]);

  const closeDialog = () => setShowDialog(null);

  const handleItemShow = (item) => {
    onActiveItemChange(item);
    setShowDialog(item);
  };

  const handleItemChange = (id) => {
    const item = _.find(filteredItems, { id });

    onActiveItemChange(item);
  };

  const handleItemReset = () => {
    onActiveItemChange(null);
  };

  const handleModeChange = (mode) => {
    setMode(mode);
  };

  const handleFiltersChange = (params) => {
    const { category, status, date } = params;
    let nextItems = _.cloneDeep(baseItems);

    if (category !== '') {
      nextItems = _.filter(nextItems, { category });
    }

    if (status !== '') {
      nextItems = _.filter(nextItems, { status });
    }

    if (date !== null) {
      nextItems = _.filter(nextItems, (item) => moment(item.date).format('DD/MM/YYYY') === date.format('DD/MM/YYYY'));
    }

    setFilteredItems(nextItems);
  };

  return (
    <div>
      <div className={ classnames(styles.wrapper, {
        [styles.wrapperOnCorner]: isOnCorner
      }) }>
        <Fade in={ isOpen && !activeItem && mode === 'list' } onExited={ handleModeChange.bind(this, 'item') }>
          <Paper className={ styles.paper } style={ { height: (size.height || 768) - 200 } }>
            <h2 className={ styles.title }>
              <span className={ styles.titleText }>Moderação</span>
              <Tooltip title="Fechar">
                <button className={ styles.titleButton } onClick={ onClose }>
                  <CloseIcon />
                </button>
              </Tooltip>
            </h2>
            <ModerationListFilters
              categoriesList={ categories }
              statusList={ status }
              onChange={ handleFiltersChange }
            />
            <ul className={ styles.dataList }>
              { _.map(filteredItems, (item) => {
                return (
                  <li key={ `moderation-item-${ item.id }` } className={ styles.dataItem }>
                    <div className={ classnames(styles.dataItemStatus, {
                      [styles.dataItemStatusPending]: item.status === 'pendente',
                      [styles.dataItemStatusApproved]: item.status === 'nada consta',
                      [styles.dataItemStatusDisapproved]: item.status === 'consta',
                    }) }>
                      <Tooltip title={ _.capitalize(item.status) }>
                        <span>
                          { item.status === 'pendente' && <HelpOutlineIcon /> }
                          { item.status === 'nada consta' && <CheckCircleIcon /> }
                          { item.status === 'consta' && <CancelIcon /> }
                        </span>
                      </Tooltip>
                    </div>
                    <div className={ styles.dataItemInfo }>
                      <span>{ item.category }</span>
                      <span>{ moment(item.date).format('DD/MM/YYYY') }</span>
                    </div>
                    <div className={ styles.dataItemActions }>
                      <Tooltip title="Gerenciar">
                        <button className={ styles.dataItemButton } onClick={ handleItemShow.bind(this, item) }>
                          <EditIcon />
                        </button>
                      </Tooltip>
                      <Tooltip title="Visualizar dados">
                        <button className={ styles.dataItemButton } onClick={ handleItemChange.bind(this, item.id) }>
                          <VisibilityIcon />
                        </button>
                      </Tooltip>
                    </div>
                  </li>
                );
              }) }
            </ul>
          </Paper>
        </Fade>
        <Fade in={ isOpen && !!activeItem && mode === 'item' }  onExited={ handleModeChange.bind(this, 'list') }>
          <Paper className={ styles.paper }>
            <h2 className={ styles.title }>
              <Tooltip title="Voltar">
                <button className={ styles.titleButton } onClick={ handleItemReset }>
                  <ChevronLeftIcon />
                </button>
              </Tooltip>
              <span className={ styles.titleText }>Moderação</span>
              <Tooltip title="Fechar">
                <button className={ styles.titleButton } onClick={ onClose }>
                  <CloseIcon />
                </button>
              </Tooltip>
            </h2>
            <ul className={ styles.dataList }>
              <li key={ `moderation-item-${ _.get(activeItem, 'id') }` } className={ styles.dataItem }>
                <div className={ classnames(styles.dataItemStatus, {
                  [styles.dataItemStatusPending]: _.get(activeItem, 'status') === 'pendente',
                  [styles.dataItemStatusApproved]: _.get(activeItem, 'status') === 'nada consta',
                  [styles.dataItemStatusDisapproved]: _.get(activeItem, 'status') === 'consta',
                }) }>
                  <Tooltip title={ _.capitalize(_.get(activeItem, 'status')) }>
                    <span>
                      { _.get(activeItem, 'status') === 'pendente' && <HelpOutlineIcon /> }
                      { _.get(activeItem, 'status') === 'nada consta' && <CheckCircleIcon /> }
                      { _.get(activeItem, 'status') === 'consta' && <CancelIcon /> }
                    </span>
                  </Tooltip>
                </div>
                <div className={ styles.dataItemInfo }>
                  <span>{ _.get(activeItem, 'category') }</span>
                  <span>{ moment(_.get(activeItem, 'date')).format('DD/MM/YYYY') }</span>
                </div>
                <div className={ styles.dataItemActions }>
                  <Tooltip title="Gerenciar">
                    <button className={ styles.dataItemButton } onClick={ handleItemShow.bind(this, activeItem) }>
                      <EditIcon />
                    </button>
                  </Tooltip>
                </div>
              </li>
            </ul>
            { loadingFeature &&
              <div className={ styles.infoGrid }>
                <CircularProgress size={ 20 } color="secondary" />
              </div>
            }
            { activeProperties &&
              <div className={ styles.infoGrid }>
                <Accordion>
                  <AccordionSummary>
                    <span className={ styles.accordionTitle }>Informações</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={ 1 }>
                      { _.map(_.keys(activeProperties), (key) => {
                        return (
                          <Grid item xs={ 6 } key={ `feature-${ _.get(activeFeature, 'id') }-${ key }` }>
                            <p className={ styles.infoItem }>
                              <span>{ key }</span>
                              <b>{ _.get(activeProperties, key) }</b>
                            </p>
                          </Grid>
                        );
                      }) }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            }
          </Paper>
        </Fade>
      </div>
      <ModerationDialog
        activeProperties={ activeProperties }
        loadingProperties={ loadingFeature }
        allLayers={ allLayers }
        isOpen={ !!showDialog }
        data={ showDialog }
        onClose={ closeDialog }
      />
    </div>
  );
}
