import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  {
    app @client {
      activeLayers {
        id
        origin
      }
      activePhotoGroup
    }
  }
`;

export const REMOVE_APRT = gql`
  mutation DeleteShps(
    $idFazenda: Int,
    $idAprt: Int,
  ) {
    deleteShps(
      idFazenda: $idFazenda,
      idAprt: $idAprt,
    ) {
      response
      status
    }
  }
`;
