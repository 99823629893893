import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import screenfull from 'screenfull';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import LayersIcon from '@material-ui/icons/Layers';
import MapIcon from '@material-ui/icons/Map';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import baseMaps from '../../../../../../data/baseMaps';

import OptionsList from '../../../../../../components/OptionsList';

import styles from './MapControls.module.scss'

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const PopperContentList = ({
  value,
  isMulti,
  options,
  title,
  onClick = () => {},
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Fragment>
      <h2 className={ styles.popperBoxTitle }>{ title }</h2>
      <OptionsList
        size={ isMobile ? 'small' : 'default' }
        isMulti={ isMulti }
        value={ value }
        options={ options }
        onClick={ onClick }
      />
    </Fragment>
  );
};

export default function MapControls({
  activeBaseLayers,
  activeBaseMap,
  baseLayers,
  boxZoomIsActive,
  pointInfoIsActive,
  isVisible,
  onBaseLayersChange = () => {},
  onBaseMapChange = () => {},
  onBoxZoomToggle = () => {},
  onPointInfoChange = () => {},
  onCenterMap = () => {},
  onZoomChange = () => {},
}) {
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [popperIsOpen, setPopperIsOpen] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [activeLayerControl, setActiveLayerControl] = useState(null);

  const handleClick = (event) => {
    setPopperAnchorEl(event.currentTarget);
    setPopperIsOpen(true);
  };

  const handleLayerControl = (_event, value) => {
    if (_.isNull(value)) {
      setPopperAnchorEl(null);
      setPopperIsOpen(false);
    }

    setActiveLayerControl(value);
  };

  const handleZoomControl = (_event, value) => {
    if (value === 'in' || value === 'out') {
      onZoomChange(value === 'in');
    }
  };

  const handleClickAway = () => {
    setPopperAnchorEl(null);
    setPopperIsOpen(false);
    setActiveLayerControl(null);
  };

  const renderPopperContent = () => {
    if (activeLayerControl === 'baseMaps') {
      return (
        <PopperContentList
          value={ activeBaseMap }
          options={ baseMaps }
          title="Mapa Base"
          onClick={ onBaseMapChange }
        />
      );
    } else {
      return (
        <PopperContentList
          isMulti
          value={ activeBaseLayers }
          options={ _.sortBy(baseLayers, 'label') }
          title="Camadas"
          onClick={ onBaseLayersChange }
        />
      );
    }
  };

  const toggleFullscreen = () => {
    const element = document.getElementById('app-content');

    if (!element || !screenfull.isEnabled) { return; }

    if (!fullscreen) {
      setFullscreen(true);
      screenfull.request(element);
    } else {
      setFullscreen(false);
      screenfull.exit();
    }
  };

  return (
    <ClickAwayListener onClickAway={ handleClickAway }>
      <Fade in={ isVisible }>
        <Paper className={ styles.wrapper }>
          <StyledToggleButtonGroup
            exclusive
            size="small"
            orientation="vertical"
            onChange={ handleZoomControl }
          >
            <Tooltip title="Ampliar zoom" placement="left">
              <ToggleButton value="in" className={ styles.toggleButton }>
                <ZoomInIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Reduzir zoom" placement="left">
              <ToggleButton value="out" className={ styles.toggleButton }>
                <ZoomOutIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Centralizar APRT" placement="left">
              <ToggleButton
                value="center"
                className={ styles.toggleButton }
                onClick={ onCenterMap }
              >
                <GpsFixedIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Selecionar área para zoom" placement="left">
              <ToggleButton
                value="boxZoom"
                className={ classnames(styles.toggleButton, {
                  [styles.toggleButtonIsActive]: boxZoomIsActive
                }) }
                onClick={ onBoxZoomToggle }
              >
                <AspectRatioIcon />
              </ToggleButton>
            </Tooltip>
          </StyledToggleButtonGroup>
          <Divider />
          <StyledToggleButtonGroup
            exclusive
            size="small"
            orientation="vertical"
            value={ activeLayerControl }
            onChange={ handleLayerControl }
          >
            <ToggleButton value="layers" className={ styles.toggleButton } onClick={ handleClick }>
              <Tooltip
                title="Camadas"
                placement="left"
                classes={ { tooltip: styles.layerControlsTooltip } }
              >
                <LayersIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="baseMaps" className={ styles.toggleButton } onClick={ handleClick }>
              <Tooltip
                title="Mapa base"
                placement="left"
                classes={ { tooltip: styles.layerControlsTooltip } }
              >
                <MapIcon />
              </Tooltip>
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Divider />
          <StyledToggleButtonGroup
            exclusive
            size="small"
            orientation="vertical"
          >
            <Tooltip title="Visualizar informações" placement="left">
              <ToggleButton
                value="viewInfo"
                className={ classnames(styles.toggleButton, {
                  [styles.toggleButtonIsActive]: pointInfoIsActive
                }) }
                onClick={ onPointInfoChange }
              >
                <NotListedLocationIcon />
              </ToggleButton>
            </Tooltip>
          </StyledToggleButtonGroup>
          <Divider />
          <StyledToggleButtonGroup
            exclusive
            size="small"
            orientation="vertical"
          >
            <Tooltip title="Tela cheia" placement="left">
              <ToggleButton
                value="fullScreen"
                className={ styles.toggleButton }
                onClick={ toggleFullscreen }
              >
                { !fullscreen ? <FullscreenIcon /> : <FullscreenExitIcon /> }
              </ToggleButton>
            </Tooltip>
          </StyledToggleButtonGroup>
          <Popper
            transition
            open={ popperIsOpen }
            anchorEl={ popperAnchorEl }
            placement="left-start"
            style={ { zIndex: 4000 } }
          >
            {({ TransitionProps }) => (
              <Fade { ...TransitionProps } timeout={ 350 }>
                <Paper className={ styles.popperBox }>
                  { renderPopperContent() }
                </Paper>
              </Fade>
            )}
          </Popper>
        </Paper>
      </Fade>
    </ClickAwayListener>
  );
}
