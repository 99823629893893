import React, { useEffect, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';

import GeometryUploadDialog from '../../components/GeometryUploadDialog';

// import api from '../../utils/api';

import styles from './Header.module.scss';

import logo from './logo.svg';
import { GET_USER } from './query';
import { clientColetor } from '../../core/apollo-client/apollo-client';

export default function Header({
  onShapefileUpload = () => {},
}) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showGeometryUpload, setShowGeometryUpload] = useState(false);
  const profile = localStorage.getItem('profile');

  const { data, refetch: refetchUser, loading } = useQuery( 
    GET_USER, 
    { 
      client: clientColetor,
      onCompleted: (data) => {
        if (data) {
          const isAdmin = _.get(data, 'user.group').name == "Administrador"

          if (isAdmin) {
            localStorage.setItem('profile', 'admin');
            // window.location.reload();
          }else{
            localStorage.removeItem('profile');
          }
          setUserData(data)
        }
      }    
    },
    
  );

  useEffect(() => {
    
    refetchUser();
 
    // api(`/users/logged`)
    //   .then((data) => {
    //     if (data) {
    //       const isAdmin = !!_.find(_.get(data, 'profile.roles'), { name: 'admin', active: true });

    //       if (isAdmin) {
    //         localStorage.setItem('profile', 'admin');
    //         // window.location.reload();
    //       }else{
    //         localStorage.removeItem('profile');
    //       }

    //       console.log(data)
    //       setUserData(data);
    //     }
    //   });
  }, []);

  
  const toggleGeometryUpload = () => {
    setShowGeometryUpload(!showGeometryUpload);

    if (!showGeometryUpload) {
      setMenuAnchorEl(null);
      setShowMenu(false);
    }
  };

  const toggleMenu = (event) => {
    if (showMenu) {
      setMenuAnchorEl(null);
      setShowMenu(false);
    } else {
      setMenuAnchorEl(event.currentTarget);
      setShowMenu(true);
    }
  };

  const userName = _.get(userData, 'user.name');
  const userAbbrev = _(userName)
    .split(' ')
    .map((w) => w.substring(0, 1))
    .slice(0, 2)
    .join('');

  return (
    <header className={ styles.wrapper }>
      <div className={ styles.content }>
        <a href="#" className={ styles.logoWrapper }>
          <img src={ logo } />
        </a>
        <div className={ styles.infoWrapper }>
          <Tooltip title={ process.env.GITLAB_BUILD_DATE } placement="bottom">
            <span className={ styles.versionTag }>APP v.{process.env.GITLAB_TAG}</span>
          </Tooltip>
  
          <div className={ styles.userInfo }>
            <span className={ styles.userInfoAbbreviation }>{ userAbbrev }</span>
            <div className={ styles.userInfoData }>
              <span className={ styles.userInfoName }>{ userName }</span>
              <span className={ styles.userInfoEmail }>{ _.get(userData, 'user.email') }</span>
            </div>
            <button className={ styles.userInfoExpandButton } onClick={ toggleMenu }>
              <ExpandMoreIcon />
            </button>
          </div>
        </div>
      </div>
      <div className={ styles.background } />
      <Popper open={ showMenu } anchorEl={ menuAnchorEl } placement="bottom-end" transition style={ { zIndex: 4000 } }>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={ styles.menuPapper }>
              <button className={ styles.menuLink } onClick={ toggleGeometryUpload }>
                <CloudUploadIcon />
                <span>Upload de Geometrias Globais</span>
              </button>
              <a href="#" className={ styles.menuLink }>
                <EditIcon />
                <span>Alterar Meus Dados</span>
              </a>
              <a href="#" className={ styles.menuLink }>
                <ExitToAppIcon />
                <span>Sair</span>
              </a>
            </Paper>
          </Fade>
        )}
      </Popper>
      <GeometryUploadDialog
        global
        isOpen={ showGeometryUpload }
        onClose={ toggleGeometryUpload }
        onShapefileUpload={ onShapefileUpload }
      />
    </header>
  );
}
