import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import CloseIcon from '@material-ui/icons/Close';

import styles from './OptionsList.module.scss';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PoperLayer from './PoperLayer'
export default function OptionsList({
  className,
  value,
  isMulti,
  options,
  size = 'default',
  onClick = () => {},
  customItemStyle = () => {},
  onRemove,
  expanded,
  aprtId,
  propertyId,
  type
}) {
  
  const [aprtMenuAnchorEl, setAprtMenuAnchorEl] = useState()
  const [item, setItem] = useState({})

  const toggleAPRTMenu = (event, option) => {
    if (!!aprtMenuAnchorEl) {
      if(aprtMenuAnchorEl !== event.currentTarget) {
        setAprtMenuAnchorEl(event.currentTarget);
        setItem(option) 
      }
      else{
        setAprtMenuAnchorEl(null);
      }
    } else {
      setAprtMenuAnchorEl(event.currentTarget);
      setItem(option) 
    }
  };

  useEffect(() => {
    setAprtMenuAnchorEl(null);
  }, [expanded])



  return (
    <ul className={ classnames(styles.list, className, {
      [styles.listSmall]: size === 'small',
      [styles.listLarge]: size === 'large',
    }) }>
      <PoperLayer aprtMenuAnchorEl={aprtMenuAnchorEl} expanded={expanded} item={item} aprtId={aprtId} propertyId={propertyId}/>
      { _.map(options, (option) => {
        const id = _.get(option, 'id');
        const label = _.get(option, 'label');
        const itemIsActive = !isMulti ? id === value : _.includes(value, id);
        const itemIsActiveWithRemove = itemIsActive && onRemove;

        return (    
          <>      
          <li
            key={ `option-item-${ id }-${ _.kebabCase(label) }` }
            className={ classnames({
              [styles.itemActive]: itemIsActive,
              [styles.itemActiveWithRemove]: itemIsActiveWithRemove,
            }) }
            style={ customItemStyle ? customItemStyle(option) : {} }
            // onClick={ !itemIsActiveWithRemove ? onClick.bind(this, id) : undefined }
          >
            <div 
             onClick={ !itemIsActiveWithRemove ? onClick.bind(this, id) : undefined }
            //onClick={() => console.log('123')}
            >
              <span>{ label }</span>
            </div>
             
            <div style={{display: 'flex'}}>
            { onRemove &&
              <button
                className={ styles.removeButton }
                onClick={ onRemove.bind(this, id) }
              >
                <CloseIcon />
              </button>
            }

          {
              type === 'camada' &&
              <button className={ styles.titleButton } onClick={ (event) => toggleAPRTMenu(event, option) }>
                <MoreVertIcon />
              </button>
            }
            </div>
            
          </li>
          </>
        );
      }) }
    </ul>
  );
};
