export default {
  'ppc:aprt_aceiro': [
    { key: 'id', label: 'id' },
    { key: 'descricao', label: 'descricao' },
    { key: 'comp_m', label: 'comp_m' },
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:aprt_app_uso_cf': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'descricao', label: 'descricao' },
    { key: 'area_ha', label: 'area_ha' },
  ],
  'ppc:aprt_app_uso_class_old': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'descricao', label: 'descricao' },
    { key: 'tipo', label: 'tipo' },
    { key: 'area_ha', label: 'area_ha' },
    { key: 'data', label: 'data' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:aprt_app_uso_mapbiomas_2008': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'val', label: 'val' },
    { key: 'descricao', label: 'descricao' },
    { key: 'tipo', label: 'tipo' },
    { key: 'area_ha', label: 'area_ha' },
    { key: 'data', label: 'data' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:aprt_app_uso_mapbiomas_2019': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'val', label: 'val' },
    { key: 'descricao', label: 'descricao' },
    { key: 'tipo', label: 'tipo' },
    { key: 'area_ha', label: 'area_ha' },
    { key: 'data', label: 'data' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:aprt_app': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'tipo', label: 'tipo' },
    { key: 'area_ha', label: 'area_ha' },
  ],
  'ppc:aprt_cerca': [
    { key: 'id', label: 'id' },
    { key: 'descricao', label: 'descricao' },
    { key: 'comp_m', label: 'comp_m' },
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:aprt_class': [
    { key: 'id', label: 'id' },
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'descricao', label: 'descricao' },
    { key: 'fonte', label: 'fonte' },
    { key: 'sensor', label: 'sensor' },
    { key: 'data', label: 'data' },
    { key: 'area_ha', label: 'area_ha' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:aprt_dados_campo': [
    { key: 'id', label: 'id' },
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'descricao', label: 'descricao' },
    { key: 'picture', label: 'picture' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:aprt_drenagem_pol': [
    { key: 'id', label: 'id' },
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'largura_m', label: 'largura_m' },
    { key: 'nome', label: 'nome' },
    { key: 'area_ha', label: 'area_ha' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'tipo', label: 'tipo' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:aprt_drenagem': [
    { key: 'id', label: 'id' },
    { key: 'nome', label: 'nome' },
    { key: 'largura_m', label: 'largura_m' },
    { key: 'comp_m', label: 'comp_m' },
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'tipo', label: 'tipo' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:aprt_erosao_controle': [
    { key: 'id', label: 'id' },
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'picture', label: 'picture' },
    { key: 'tipo', label: 'tipo' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:aprt_erosao': [
    { key: 'id', label: 'id' },
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'descricao', label: 'descricao' },
    { key: 'picture', label: 'picture' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:aprt_lagoa': [
    { key: 'id', label: 'id' },
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'nome', label: 'nome' },
    { key: 'area_ha', label: 'area_ha' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:aprt_limite': [
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'fazenda', label: 'fazenda' },
    { key: 'proprietario', label: 'proprietario' },
    { key: 'municipio', label: 'municipio' },
    { key: 'estado', label: 'estado' },
    { key: 'x', label: 'x' },
    { key: 'y', label: 'y' },
    { key: 'codigo', label: 'codigo' },
    { key: 'srid', label: 'srid' },
    { key: 'area_ha', label: 'area_ha' },
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'perim_km', label: 'perim_km' },
    { key: 'created_at', label: 'created_at' },
    { key: 'cd_mun', label: 'cd_mun' },
    { key: 'id', label: 'id' },
    { key: 'id_tabela', label: 'id_tabela' },
    { key: 'fonte', label: 'fonte' },
    { key: 'main_class', label: 'main_class' },
    { key: 'class_name', label: 'class_name' },
    { key: 'image_date', label: 'image_date' },
    { key: 'year', label: 'year' },
    { key: 'bioma', label: 'bioma' },
  ],
  'ppc:aprt_sede': [
    { key: 'id', label: 'id' },
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'created_at', label: 'created_at' },
  ],
  'ppc:limite_brasil': [
    { key: 'id', label: 'id' },
    { key: 'nome', label: 'nome' },
    { key: 'sigla', label: 'sigla' },
  ],
  'ppc:limite_estados': [
    { key: 'nm_uf', label: 'nm_uf' },
    { key: 'sigla_uf', label: 'sigla_uf' },
    { key: 'cd_uf', label: 'cd_uf' },
  ],
  'ppc:limite_municipios': [
    { key: 'cd_mun', label: 'cd_mun' },
    { key: 'cd_uf', label: 'cd_uf' },
    { key: 'sigla_uf', label: 'sigla_uf' },
    { key: 'nm_mun', label: 'nm_mun' },
  ],
  'ppc:rodovias': [
    { key: 'id_rodovia', label: 'id_rodovia' },
    { key: 'tipovia', label: 'tipovia' },
    { key: 'jurisdicao', label: 'jurisdicao' },
    { key: 'administra', label: 'administra' },
    { key: 'concession', label: 'concession' },
    { key: 'revestimen', label: 'revestimen' },
    { key: 'operaciona', label: 'operaciona' },
    { key: 'situacaofi', label: 'situacaofi' },
    { key: 'nrpistas', label: 'nrpistas' },
    { key: 'nrfaixas', label: 'nrfaixas' },
    { key: 'trafego', label: 'trafego' },
    { key: 'codtrechor', label: 'codtrechor' },
  ],
  'ppc:sedes_municipais': [
    { key: 'nm_mun', label: 'nm_mun' },
    { key: 'cd_mun', label: 'cd_mun' },
  ],
  'ppc:v_desmatamento_mapbiomas_alertas': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'anodetec', label: 'anodetec' },
    { key: 'datadetec', label: 'datadetec' },
  ],
  'ppc:v_desmatamento': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'year', label: 'year' },
    { key: 'image_date', label: 'image_date' },
    { key: 'bioma', label: 'bioma' },
  ],
  'ppc:v_focos_calor': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'contagem', label: 'contagem' },
    { key: 'ano', label: 'ano' },
  ],
  'ppc:v_ibama_asv': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'num_autori', label: 'num_autori' },
    { key: 'data_validade', label: 'data_validade' },
  ],
  'ppc:v_ibama_auto_infracao': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'num_auto_i', label: 'num_auto_i' },
    { key: 'dat_hora_a', label: 'dat_hora_a' },
    { key: 'val_auto_i', label: 'val_auto_i' },
    { key: 'des_auto_i', label: 'des_auto_i' },
  ],
  'ppc:v_ibama_embargos': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'data_cadas', label: 'data_cadas' },
    { key: 'num_auto_i', label: 'num_auto_i' },
    { key: 'des_infrac', label: 'des_infrac' },
  ],
  'ppc:v_limite_amz_legal': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
  ],
  'ppc:v_limite_assentamentos': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'nome', label: 'nome' },
    { key: 'area_ti', label: 'area_ti' },
    { key: 'municipio', label: 'municipio' },
    { key: 'sigla_uf', label: 'sigla_uf' },
  ],
  'ppc:v_limite_biomas': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'bioma', label: 'bioma' },
    { key: 'pct', label: 'pct' },
  ],
  'ppc:v_limite_car': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'cod_imovel', label: 'cod_imovel' },
    { key: 'area_imovel', label: 'area_imovel' },
  ],
  'ppc:v_limite_ottobacias_nivel2': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'num_ottobacia', label: 'num_ottobacia' },
  ],
  'ppc:v_limite_sigef': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'cod_imovel', label: 'cod_imovel' },
    { key: 'nome', label: 'nome' },
    { key: 'area_imovel', label: 'area_imovel' },
    { key: 'tipo', label: 'tipo' },
    { key: 'status', label: 'status' },
    { key: 'registro_m', label: 'registro_m' },
    { key: 'art', label: 'art' },
  ],
  'ppc:v_limite_snci': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'cod_imovel', label: 'cod_imovel' },
    { key: 'nome', label: 'nome' },
    { key: 'area_imovel', label: 'area_imovel' },
    { key: 'tipo', label: 'tipo' },
    { key: 'num_certificado', label: 'num_certificado' },
  ],
  'ppc:v_limite_tis': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'nome', label: 'nome' },
    { key: 'area_ti', label: 'area_ti' },
    { key: 'municipio_', label: 'municipio_' },
    { key: 'uf_sigla', label: 'uf_sigla' },
  ],
  'ppc:v_limite_ucs': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'nome', label: 'nome' },
    { key: 'area_uc', label: 'area_uc' },
    { key: 'grupo', label: 'grupo' },
    { key: 'municipio', label: 'municipio' },
    { key: 'estado', label: 'estado' },
  ],
  'ppc:v_solos': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'tipo_solo', label: 'tipo_solo' },
    { key: 'pct', label: 'pct' },
  ],
  'ppc:v_uso_mapbiomas_2008': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'descricao', label: 'descricao' },
  ],
  'ppc:v_uso_mapbiomas_2019': [
    { key: 'id_fazenda', label: 'id_fazenda' },
    { key: 'id_aprt', label: 'id_aprt' },
    { key: 'criado_em', label: 'criado_em' },
    { key: 'valor', label: 'valor' },
    { key: 'unidade', label: 'unidade' },
    { key: 'descricao', label: 'descricao' },
  ],
  'ppc:limite_car': [
    { key: 'area_ha', label: 'area_ha' },
    { key: 'cod_imovel', label: 'cod_imovel' },
    { key: 'id', label: 'id' },
    { key: 'lat', label: 'lat' },
    { key: 'long', label: 'long' },
    { key: 'modulos_fiscais', label: 'modulos_fiscais' },
    { key: 'tamanho_imovel', label: 'tamanho_imovel' },
    { key: 'tipo', label: 'tipo' },
  ],
  'ppc:app_declividade': [
    { key: 'id', label: 'id' },
    { key: 'count', label: 'count' },
    { key: 'class', label: 'class' },
  ],
  'ppc:app_elevacao': [
    { key: 'id', label: 'id' },
    { key: 'count', label: 'count' },
    { key: 'class', label: 'class' },
  ],
  'ppc:desmatamento': [
    { key: 'id', label: 'id' },
    { key: 'idalerta', label: 'idalerta' },
    { key: 'bioma', label: 'bioma' },
    { key: 'estado', label: 'estado' },
    { key: 'municipio', label: 'municipio' },
    { key: 'areaha', label: 'areaha' },
    { key: 'anodetec', label: 'anodetec' },
    { key: 'datadetec', label: 'datadetec' },
  ],
  'ppc:desmatamento_mapbiomas_alertas': [
    { key: 'id', label: 'id' },
    { key: 'datahora', label: 'datahora' },
    { key: 'satelite', label: 'satelite' },
    { key: 'pais', label: 'pais' },
    { key: 'estado', label: 'estado' },
    { key: 'municipio', label: 'municipio' },
    { key: 'bioma', label: 'bioma' },
    { key: 'diasemchuva', label: 'diasemchuva' },
    { key: 'precipitacao', label: 'precipitacao' },
    { key: 'riscofogo', label: 'riscofogo' },
    { key: 'latitude', label: 'latitude' },
    { key: 'longitude', label: 'longitude' },
    { key: 'frp', label: 'frp' },
    { key: 'ano', label: 'ano' },
  ],
  'ppc:focos_calor': [
    { key: 'id', label: 'id' },
    { key: 'cod_origem', label: 'cod_origem' },
    { key: 'num_autori', label: 'num_autori' },
    { key: 'nom_ativid', label: 'nom_ativid' },
    { key: 'dat_valida', label: 'dat_valida' },
    { key: 'dat_vali_1', label: 'dat_vali_1' },
    { key: 'tipo_area', label: 'tipo_area' },
    { key: 'cod_imovel', label: 'cod_imovel' },
  ],
  'ppc:ibama_auto_infracao': [
    { key: 'id', label: 'id' },
    { key: 'seq_infrac', label: 'seq_infrac' },
    { key: 'nom_pessoa', label: 'nom_pessoa' },
    { key: 'dat_hora_a', label: 'dat_hora_a' },
    { key: 'cod_uf', label: 'cod_uf' },
    { key: 'cod_munici', label: 'cod_munici' },
    { key: 'num_pessoa', label: 'num_pessoa' },
    { key: 'num_auto_i', label: 'num_auto_i' },
    { key: 'ser_auto_i', label: 'ser_auto_i' },
    { key: 'val_auto_i', label: 'val_auto_i' },
    { key: 'num_proces', label: 'num_proces' },
    { key: 'des_auto_i', label: 'des_auto_i' },
  ],
  'ppc:ibama_embargos': [
    { key: 'id', label: 'id' },
    { key: 'nom_pessoa', label: 'nom_pessoa' },
    { key: 'cpf_cnpj_i', label: 'cpf_cnpj_i' },
    { key: 'seq_tad', label: 'seq_tad' },
    { key: 'numero_tad', label: 'numero_tad' },
    { key: 'data_tad', label: 'data_tad' },
    { key: 'num_latitu', label: 'num_latitu' },
    { key: 'num_longit', label: 'num_longit' },
    { key: 'processo_t', label: 'processo_t' },
    { key: 'sig_uf', label: 'sig_uf' },
    { key: 'nom_munici', label: 'nom_munici' },
    { key: 'num_auto_i', label: 'num_auto_i' },
    { key: 'ser_auto_i', label: 'ser_auto_i' },
    { key: 'cod_munici', label: 'cod_munici' },
    { key: 'cod_uf', label: 'cod_uf' },
    { key: 'status_tad', label: 'status_tad' },
    { key: 'qtd_area_d', label: 'qtd_area_d' },
    { key: 'data_cadas', label: 'data_cadas' },
    { key: 'des_infrac', label: 'des_infrac' },
    { key: 'serie_tad', label: 'serie_tad' },
    { key: 'sit_embarg', label: 'sit_embarg' },
    { key: 'legislacao', label: 'legislacao' },
    { key: 'artigo_leg', label: 'artigo_leg' },
    { key: 'artigo', label: 'artigo' },
    { key: 'imagem_val', label: 'imagem_val' },
    { key: 'respeita_e', label: 'respeita_e' },
    { key: 'data_geom', label: 'data_geom' },
    { key: 'orgao', label: 'orgao' },
  ],
  'ppc:limite_amz_legal': [
    { key: 'id', label: 'id' },
  ],
  'ppc:limite_assentamentos': [
    { key: 'id', label: 'id' },
    { key: 'cd_sipra', label: 'cd_sipra' },
    { key: 'sigla_uf', label: 'sigla_uf' },
    { key: 'nome', label: 'nome' },
    { key: 'municipio', label: 'municipio' },
    { key: 'capacidade', label: 'capacidade' },
    { key: 'num_familias', label: 'num_familias' },
    { key: 'fase', label: 'fase' },
    { key: 'data_criacao', label: 'data_criacao' },
    { key: 'forma_obtencao', label: 'forma_obtencao' },
    { key: 'data_obtencao', label: 'data_obtencao' },
    { key: 'area_ha', label: 'area_ha' },
    { key: 'sr', label: 'sr' },
    { key: 'descricao', label: 'descricao' },
  ],
  'ppc:limite_biomas': [
    { key: 'id', label: 'id' },
    { key: 'id_bioma', label: 'id_bioma' },
    { key: 'bioma', label: 'bioma' },
    { key: 'area_ha', label: 'area_ha' },
  ],
  'ppc:limite_ottobacias_nivel2': [
    { key: 'id', label: 'id' },
    { key: 'nunivotto2', label: 'nunivotto2' },
    { key: 'versao', label: 'versao' },
  ],
  'ppc:limite_snci': [
    { key: 'id', label: 'id' },
    { key: 'nome', label: 'nome' },
    { key: 'tipo', label: 'tipo' },
    { key: 'num_processo', label: 'num_processo' },
    { key: 'sr', label: 'sr' },
    { key: 'num_certificado', label: 'num_certificado' },
    { key: 'data_certificado', label: 'data_certificado' },
    { key: 'cod_profis', label: 'cod_profis' },
    { key: 'cod_imovel', label: 'cod_imovel' },
    { key: 'sigla_uf', label: 'sigla_uf' },
    { key: 'area_ha', label: 'area_ha' },
  ],
  'ppc:limite_ucs': [
    { key: 'id', label: 'id' },
    { key: 'nome', label: 'nome' },
    { key: 'categoria', label: 'categoria' },
    { key: 'grupo', label: 'grupo' },
    { key: 'esfera', label: 'esfera' },
    { key: 'ano_criacao', label: 'ano_criacao' },
    { key: 'qualidade', label: 'qualidade' },
    { key: 'ato_legal', label: 'ato_legal' },
    { key: 'dt_atualizacao', label: 'dt_atualizacao' },
    { key: 'nome_orgao', label: 'nome_orgao' },
    { key: 'area_ha', label: 'area_ha' },
    { key: 'municipio', label: 'municipio' },
    { key: 'estado', label: 'estado' },
  ],
  'ppc:solos': [
    { key: 'id_solos', label: 'id_solos' },
    { key: 'id', label: 'id' },
    { key: 'cd_fcim', label: 'cd_fcim' },
    { key: 'nom_unidad', label: 'nom_unidad' },
    { key: 'cod_simbol', label: 'cod_simbol' },
    { key: 'val_ncompo', label: 'val_ncompo' },
    { key: 'legenda', label: 'legenda' },
    { key: 'ordem', label: 'ordem' },
    { key: 'subordem', label: 'subordem' },
    { key: 'grande_gru', label: 'grande_gru' },
    { key: 'subgrupos', label: 'subgrupos' },
    { key: 'textura', label: 'textura' },
    { key: 'horizonte', label: 'horizonte' },
    { key: 'erosao', label: 'erosao' },
    { key: 'pedregosid', label: 'pedregosid' },
    { key: 'rochosidad', label: 'rochosidad' },
    { key: 'relevo', label: 'relevo' },
    { key: 'componente', label: 'componente' },
    { key: 'component1', label: 'component1' },
    { key: 'component2', label: 'component2' },
    { key: 'component3', label: 'component3' },
    { key: 'inclu_p1', label: 'inclu_p1' },
    { key: 'inclu_p2', label: 'inclu_p2' },
    { key: 'inclu_p3', label: 'inclu_p3' },
    { key: 'md_ar_poli', label: 'md_ar_poli' },
    { key: 'id1', label: 'id1' },
    { key: 'value_ord', label: 'value_ord' },
    { key: 'value_leg', label: 'value_leg' },
    { key: 'legenda_su', label: 'legenda_su' },
    { key: 'legenda_or', label: 'legenda_or' },
    { key: 'orig_fid', label: 'orig_fid' },
    { key: 'legenda_gsv', label: 'legenda_gsv' },
  ],
  'ppc:uso_mapbiomas_2008': [
    { key: 'id', label: 'id' },
    { key: 'rid', label: 'rid' },
    { key: 'val', label: 'val' },
  ],
  'ppc:uso_mapbiomas_2019': [
    { key: 'id', label: 'id' },
    { key: 'rid', label: 'rid' },
    { key: 'val', label: 'val' },
  ],
  'ppc:limite_tis': [
    { key: 'id', label: 'id' },
    { key: 'terrai_cod', label: 'terrai_cod' },
    { key: 'nome', label: 'nome' },
    { key: 'etnia_nome', label: 'etnia_nome' },
    { key: 'municipio_', label: 'municipio_' },
    { key: 'uf_sigla', label: 'uf_sigla' },
    { key: 'superficie', label: 'superficie' },
    { key: 'fase_ti', label: 'fase_ti' },
    { key: 'modalidade', label: 'modalidade' },
    { key: 'reestudo_t', label: 'reestudo_t' },
    { key: 'cr', label: 'cr' },
    { key: 'faixa_fron', label: 'faixa_fron' },
    { key: 'undadm_cod', label: 'undadm_cod' },
    { key: 'undadm_nom', label: 'undadm_nom' },
    { key: 'undadm_sig', label: 'undadm_sig' },
    { key: 'dominio_un', label: 'dominio_un' },
    { key: 'area_ha', label: 'area_ha' },
  ]
};
