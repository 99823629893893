import React from 'react';
import _ from 'lodash';
import { useField } from 'formik';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

export default function FormikFileInput(props) {
  const {
    field: { name },
    accept,
    label,
    required,
    disabled,
  } = props;
  const [_field, meta, helpers] = useField(name);
  const showError = meta.touched && !!meta.error;

  const handleChange = (event) => {
    const file = event.target.files[0];

    helpers.setValue(file);
  };

  return (
    <TextField
      error={ showError }
      disabled={ disabled }
      required={ required }
      label={ label }
      inputProps={ {
        accept
      } }
      helperText={ showError ? (meta.error || '') : null }
      InputProps={{
        type: 'file',
        startAdornment: <InputAdornment position="start"><AttachFileIcon /></InputAdornment>,
      }}
      variant="filled"
      onChange={ handleChange }
    />
  );
}
