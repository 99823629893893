import { gql } from 'graphql.macro';

export const GET_MODERATION_DATA = gql`
  query GetModerationData(
    $moderationId: Int!,
  ) {
    moderacaoAndamento(idModeracao: $moderationId){
      id
      idModeracao
      idUser
      comentario
      createdAt
      fileRef
      url
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation UpdateStatusModeracao(
    $id: Int!,
    $status: String!,
  ) {
    updateStatusModeracao(
      id: $id,
      status: $status,
    ) {
      response
      status
      moderacao {
        id
        createdAt
        idAprt
        idCamada
        idFazenda
        idFeicao
        status
        updatedAt
        valor
        nome
        unidade
      }
    }
  }
`;
