import React, { useEffect, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import classnames from 'classnames';
import * as turf from '@turf/turf';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import LandscapeIcon from '@material-ui/icons/Landscape';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';

import BasePage from '../../layout/BasePage';

import ActiveFarmControl from './components/ActiveFarmControl';
import LandRegistryBox from './components/LandRegistryBox';
import LayersBox from './components/LayersBox';
import MapCanvas from './components/MapCanvas';
import MapLegend from './components/MapLegend';
import ModerationBox from './components/ModerationBox';
import PointInfoBox from './components/PointInfoBox';
import ShapefileMonitor from './components/ShapefileMonitor';
import ProcessAPPBox from './components/ProcessAPPBox';

import api from '../../utils/api';
import toast from '../../utils/toast';

import generateLayersGroups from '../../data/layerGroups';
import parseBaseLayers from '../../data/baseLayers';

import fetchLayerPointData from '../../helpers/fetchLayerPointData';
import fetchPropertyLayerData from '../../helpers/fetchPropertyLayerData';
import fetchPropertyLayerPathData from '../../helpers/fetchPropertyLayerPathData';
import fetchPropertyCodeFeaturesData from '../../helpers/fetchPropertyCodeFeaturesData';

import {
  GET_APRT_DATA,
  GET_BASE_DATA,
  GET_FARM_DATA,
  GET_MODERATION_DATA,
  RUN_OVERLAP,
} from './query';

import styles from './LandingPage.module.scss';
import { clientColetor } from '../../core/apollo-client/apollo-client';

const mapLandRegistryKeyToLayerId = {
  snci: 'ppc:limite_snci',
  sigef: 'ppc:limite_sigef',
  car: 'ppc:limite_car',
};

const mapLandRegistryKeyToPropertyLayerId = {
  snci: 'ppc:v_limite_snci',
  sigef: 'ppc:v_limite_sigef',
  car: 'ppc:v_limite_car',
};

export default function LandingPage({
  match
}) {
  const propertyId = Number(_.get(match, 'params.propertyId'));
  const client = useApolloClient();

  const [aprtId, setAPRTId] = useState(null);

  const { data: aprtData, loading: loadingAprtData, refetch: refetchAprtData } = useQuery(GET_APRT_DATA, {
    variables: {
      propertyId
    }
  });

  const { data: baseData, refetch: refetchBaseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      propertyId,
      aprtId
    },
    skip: !aprtId || loadingAprtData
  });

  useEffect(() => {
    if (aprtData) {
      const aprtItem = _.maxBy(_.get(aprtData, 'listAprts'), 'idAprt');
      if (aprtItem) {
        setAPRTId(_.get(aprtItem, 'idAprt'));
      }
    }
  }, [aprtData]);

  const allLayers = _.get(baseData, 'camadas');
  const layers = _.get(baseData, 'camadasDisp');
  const layerGroups = generateLayersGroups(layers);
  const baseLayers = parseBaseLayers(allLayers);

  const [runOverlap] = useMutation(RUN_OVERLAP, {
    onCompleted: (data) => {
      if (_.get(data, 'runOverlap.status') === true) {
        toast('Processamento realizado com sucesso!');
      }
    },
    onError: () => toast('Ocorreu um erro!', 'error')
  });

  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isLoadingFarmData, setIsLoadingFarmData] = useState(true);
  const [isLookingForFeatureSiblings, setIsLookingForFeatureSiblings] = useState(false);
  const [isSearchForCARFeatures, setIsSearchForCARFeatures] = useState(false);
  const [contentIsHidden, setContentIsHidden] = useState(false);
  const [pointInfoData, setPointInfoData] = useState(null);
  const [propertyData, setPropertyData] = useState(null);
  const [showModerationBox, setShowModerationBox] = useState(false);
  const [mapFlyTo, setMapFlyTo] = useState(null);
  const [cityCode, setCityCode] = useState(null);
  const [landRegistryAnchorEl, setLandRegistryAnchorEl] = useState(null);
  const [landRegistryCategory, setLandRegistryCategory] = useState(null);
  const [landRegistryFeatures, setLandRegistryFeatures] = useState([]);
  const [processAPPAnchorEl, setProcessAPPAnchorEl] = useState(null);
  const [photosData, setPhotosData] = useState([]);
  const [photosDataTest, setPhotosDataTest] = useState([]);
  const [activeModerationItem, setActiveModerationItem] = useState(null);
  const [activeModerationFeature, setActiveModerationFeature] = useState(null);
  const [loadingModerationFeature, setLoadingModerationFeature] = useState(false);
  const [currentShapefileIds, setCurrentShapefileIds] = useState([]);

  const { data: moderationData } = useQuery(GET_MODERATION_DATA, {
    variables: {
      propertyId,
      aprtId,
    },
    skip: !aprtId
  });

  const [propertyDataTest, setPropertyDataTest] = useState(null)

  const { data, refetch: refetchFarmData, loading } = useQuery(
    GET_FARM_DATA, 
    {
      variables: {
        propertyId
      },
      client: clientColetor,
      onCompleted: (data) => {
        setPropertyDataTest(data)
        setIsLoadingFarmData(false);
        setIsLoadingInitialData(false);
        setDefaultAPRTLayer();


        const geoserverBaseUrl = `${ process.env.GEOSERVER_URL }ppc/ows?`;
        const wfsParams = [
          'service=WFS',
          'version=1.0.0',
          'request=GetFeature',
          `typeName=ppc:aprt_limite`,
          'outputFormat=application/json',
          'propertyName=fazenda,x,y,cd_mun',
          `cql_filter=id_fazenda=${ propertyId }`,
        ];

        if(aprtId){
          fetch(`${ geoserverBaseUrl }${ wfsParams.join('&') }`)
            .then((resp) => resp.json())
            .then((geoserverData) => {
              const feature = _.find(_.get(geoserverData, 'features'), ({ properties: { id_aprt } }) => id_aprt === aprtId);
              const bbox = _.get(feature, 'bbox');
              const cityCode = _.get(feature, 'properties.cd_mun');

              setCityCode(cityCode);

              if (bbox) {
                setMapFlyTo([
                  [bbox[1], bbox[0]],
                  [bbox[3], bbox[2]],
                ]);
              } else {
                // const lat = Number(_.replace(_.get(propertyData, 'latitude'), ',', '.'));
                // const lng = Number(_.replace(_.get(propertyData, 'longitude'), ',', '.'));
                const lat = Number(_.replace(_.get(propertyDataTest, 'farm.latitude'), ',', '.'));
                const lng = Number(_.replace(_.get(propertyDataTest, 'farm.longitude'), ',', '.'));

                if (lat && lng && !_.isNaN(lat) && !_.isNaN(lng) && _.isNumber(lat) && _.isNumber(lng)) {
                  setMapFlyTo([lat, lng]);
                } else {
                  alert('Não foi possível encontrar os valores para latitude e longitude da proprieddade.');
                }
              }
            });
        }

        setPhotosDataTest(_.get(data, 'farm.image'))

        console.log(data)
        console.log(_.get(data, 'farm.image'))
        // setPhotosData(filteredPhotos(data));
      }
    }
  )

  const filteredPhotos = (data) => {
    return _.filter(data, (item) => {
      const name = _.get(item, 'name');

      return _.includes(name, '.jpg') || _.includes(name, '.png');
    });
  }


  const moderationItems = _.get(moderationData, 'moderacaoResultadoCruzamentos');

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params
        }
      }
    });
  };

  const setDefaultAPRTLayer = () => {
    const defaultLayer = { __typename: 'LayerItem', id: 'ppc:aprt_limite', origin: 'group' };
    updateClientAppState({ activeLayers: [defaultLayer] });
  };

  useEffect(() => {
    refetchFarmData()
  }, [])


  useEffect(() => {
    if (propertyId) {

      // api(`/farms/${ propertyId }`)
      //   .then((propertyData) => {
      //     if (propertyData) {
      //       console.log(propertyData)
      //       setPropertyData(propertyData);
      //       setIsLoadingFarmData(false);
      //       setIsLoadingInitialData(false);
      //       setDefaultAPRTLayer();

      //       // Load APRT bounding box from geoserver

      //       const geoserverBaseUrl = `${ process.env.GEOSERVER_URL }ppc/ows?`;
      //       const wfsParams = [
      //         'service=WFS',
      //         'version=1.0.0',
      //         'request=GetFeature',
      //         `typeName=ppc:aprt_limite`,
      //         'outputFormat=application/json',
      //         'propertyName=fazenda,x,y,cd_mun',
      //         `cql_filter=id_fazenda=${ propertyId }`,
      //       ];

      //       if(aprtId){
      //         fetch(`${ geoserverBaseUrl }${ wfsParams.join('&') }`)
      //           .then((resp) => resp.json())
      //           .then((geoserverData) => {
      //             const feature = _.find(_.get(geoserverData, 'features'), ({ properties: { id_aprt } }) => id_aprt === aprtId);
      //             const bbox = _.get(feature, 'bbox');
      //             const cityCode = _.get(feature, 'properties.cd_mun');

      //             setCityCode(cityCode);

      //             if (bbox) {
      //               setMapFlyTo([
      //                 [bbox[1], bbox[0]],
      //                 [bbox[3], bbox[2]],
      //               ]);
      //             } else {
      //               // const lat = Number(_.replace(_.get(propertyData, 'latitude'), ',', '.'));
      //               // const lng = Number(_.replace(_.get(propertyData, 'longitude'), ',', '.'));
      //               const lat = Number(_.replace(_.get(propertyDataTest, 'farm.latitude'), ',', '.'));
      //               const lng = Number(_.replace(_.get(propertyDataTest, 'farm.longitude'), ',', '.'));

      //               if (lat && lng && !_.isNaN(lat) && !_.isNaN(lng) && _.isNumber(lat) && _.isNumber(lng)) {
      //                 setMapFlyTo([lat, lng]);
      //               } else {
      //                 alert('Não foi possível encontrar os valores para latitude e longitude da proprieddade.');
      //               }
      //             }
      //           });
      //       }
              
      //     }
      //   });
      // api(`/farms/${ propertyId }/files`)
      //   .then((data) => {
      //     const filteredPhotos = _.filter(data, (item) => {
      //       const name = _.get(item, 'name');

      //       return _.includes(name, '.jpg') || _.includes(name, '.png');
      //     });

      //     setPhotosData(filteredPhotos);
      //   });
    }
  }, [aprtId]);

  // useEffect(() => {
  //   if (propertyId) {
  //     setIsLoadingFarmData(true);
  //     api(`/farms/${ propertyId }`)
  //       .then((data) => {
  //         if (data) {
  //           setPropertyData(data);
  //           setIsLoadingFarmData(false);
  //           setDefaultAPRTLayer();
  //         }
  //       });
  //   }
  // }, [propertyId]);

  const toggleContent = () => setContentIsHidden(!contentIsHidden);

  const toggleModerationBox = () => setShowModerationBox(!showModerationBox);

  const handlePointInfoChange = (data) => setPointInfoData(data);

  const handleAprtIdChange = (id) => {
    setAPRTId(id)
    handleLandRegistryBoxClose();
    refetchBaseData({
      variables: {
        propertyId,
        aprtId: id
      }
    });
  };

  const handleAprtRemove = () => {
    setAPRTId(null);
    refetchAprtData({
      variables: {
        propertyId,
      }
    });
  };

  const resetPointInfo = () => setPointInfoData(null);

  const handleLandRegistryPointChange = (data) => {
    if (data) {
      const layerId = mapLandRegistryKeyToLayerId[landRegistryCategory];
      fetchLayerPointData(layerId, _.get(data, 'bboxString'))
        .then((response) => {
          const features = _.get(response, 'features');
          let feature = _.first(features);

          if (_.size(features) > 1) {
            const coordinates = _.get(data, 'coordinates');
            const point = turf.point([coordinates[1], coordinates[0]]);
            feature = _.find(features, (feature) => {
              return turf.booleanPointInPolygon(point, feature);
            });
          }

          if (feature) {
            const featureIds = _.map(landRegistryFeatures, 'properties.cod_imovel');
            const featureId = _.get(feature, 'properties.cod_imovel');
            let nextFeatures;

            if (_.includes(featureIds, featureId)) {
              nextFeatures = _.filter(_.cloneDeep(landRegistryFeatures), (feature) => {
                return _.get(feature, 'properties.cod_imovel') !== featureId;
              });

              setLandRegistryFeatures(nextFeatures);
            } else {
              nextFeatures = [
                ...landRegistryFeatures,
                feature
              ];

              setLandRegistryFeatures(nextFeatures);
              setIsLookingForFeatureSiblings(true);

              fetchPropertyCodeFeaturesData(layerId, featureId)
                .then((response) => {
                  const features = _.get(response, 'features');

                  if (_.size(features) > 1) {
                    nextFeatures = _.filter(nextFeatures, (feature) => {
                      return _.get(feature, 'properties.cod_imovel') !== featureId;
                    });
                    nextFeatures = [
                      ...landRegistryFeatures,
                      ...features,
                    ];

                    setLandRegistryFeatures(nextFeatures);
                  }

                  setIsLookingForFeatureSiblings(false);
                });
            }
          }
        });
    }
  };

  const handleLandRegistryCARSearch = (code) => {
    setIsSearchForCARFeatures(true);

    fetchPropertyCodeFeaturesData('ppc:limite_car', code)
      .then((response) => {
        const features = _.get(response, 'features');

        if (_.size(features) >= 1) {
          let nextFeatures = _.cloneDeep(landRegistryFeatures);
          nextFeatures = _.filter(nextFeatures, (feature) => {
            return _.get(feature, 'properties.cod_imovel') !== code;
          });
          nextFeatures = [
            ...landRegistryFeatures,
            ...features,
          ];

          setLandRegistryFeatures(nextFeatures);
        } else {
          toast('Nenhum resultado encontrado.', 'error');
        }

        setIsSearchForCARFeatures(false);
      });
  };

  const toggleLandRegistryMenu = (event) => {
    if (!!landRegistryAnchorEl) {
      setLandRegistryAnchorEl(null);
    } else {
      setLandRegistryAnchorEl(event.currentTarget);
    }
  };

  const toggleProcessAPPBox = (event) => {
    if (!!processAPPAnchorEl) {
      setProcessAPPAnchorEl(null);
    } else {
      setProcessAPPAnchorEl(event.currentTarget);
    }
  };

  const handleLandRegistryAnchorEl = (key) => {
    setLandRegistryAnchorEl(null);
    setLandRegistryCategory(key);

    updateClientAppState({
      activeBaseLayers: [
        mapLandRegistryKeyToLayerId[key]
      ]
    });

    const layerId = mapLandRegistryKeyToPropertyLayerId[key];
    fetchPropertyLayerData(layerId, propertyId, aprtId)
      .then((response) => {
        const features = _.get(response, 'features');
        setLandRegistryFeatures(features);
      });
  };

  const handleLandRegistryBoxClose = () => {
    setLandRegistryAnchorEl(null);
    setLandRegistryCategory(null);
    setLandRegistryFeatures([]);
    updateClientAppState({
      activeBaseLayers: []
    });
  };

  const handleModerationItemChange = (data) => {
    if (data) {
      setActiveModerationItem(data);
      setLoadingModerationFeature(true);

      const activeModerationLayerData = _.find(allLayers, { idCamada: _.get(data, 'layerId') });
      fetchPropertyLayerPathData(
        `ppc:v_${ _.get(activeModerationLayerData, 'tabela') }`,
        propertyId,
        _.get(data, 'pathId')
      )
        .then((data) => {
          const feature = _.get(data, 'features[0]');
          const bbox = _.get(feature, 'bbox');

          if (feature) {
            setActiveModerationFeature(feature);
          }

          if (bbox) {
            setMapFlyTo([
              [bbox[1], bbox[0]],
              [bbox[3], bbox[2]],
            ]);
          }

          setLoadingModerationFeature(false);
        });
    } else {
      setActiveModerationItem(null);
      setActiveModerationFeature(null);
    }
  };

  const handleModerationBoxClose = () => {
    setShowModerationBox(false);
    setActiveModerationItem(null);
    setActiveModerationFeature(null);
  };

  const handleRunOverlap = () => {
    if (aprtId && propertyId) {
      runOverlap({
        variables: {
          idFazenda: propertyId,
          idAprt: aprtId
        }
      });
    } else {
      alert('Não é possível realizar o processamento.');
    }
  };

  const handleShapefileUpload = (id) => {
    setCurrentShapefileIds([
      ...currentShapefileIds,
      id
    ]);
  };

  const handleShapefileMonitorClose = () => {
    setCurrentShapefileIds([]);
  };

  const refreshBaseData = () => {    
    refetchBaseData({
      variables: {
        propertyId,
        aprtId
      }
    });
  };

  const refreshAprtData = () => {   
    refetchAprtData({
        variables: {
          propertyId,
        }
      });
  };


  if (isLoadingInitialData) {
    return (
      <div className={ styles.mainLoadingWrapper }>
        <CircularProgress />
      </div>
    );
  }

  return (
    <BasePage onShapefileUpload={ handleShapefileUpload }>
      <MapCanvas
        activeModerationItem={ activeModerationItem }
        allLayers={ allLayers }
        aprtId={ aprtId }
        landRegistryCategory={ landRegistryCategory }
        landRegistryFeatures={ landRegistryFeatures }
        baseLayers={ baseLayers }
        flyTo={ mapFlyTo }
        propertyId={ propertyId }
        leftContentIsHidden={ contentIsHidden }
        pointInfoData={ pointInfoData }
        photosData={ photosDataTest }
        onPointInfoClick={ handlePointInfoChange }
        onLandRegistryPointClick={ handleLandRegistryPointChange }
        resetPointInfo={ resetPointInfo }
      />
      <div className={ classnames(styles.leftContent, {
        [styles.leftContentIsHidden]: contentIsHidden
      }) }>
        <ActiveFarmControl data={ propertyDataTest}  />
        <LayersBox
          aprtList={ _.get(aprtData, 'listAprts') }
          propertyId={ propertyId }
          aprtId={ aprtId }
          cityCode={ cityCode }
          layerGroups={ layerGroups }
          moderationBoxIsVisible={ showModerationBox }
          // photosData={ photosData }
          photosData={ photosDataTest }
          onShapefileUpload={ handleShapefileUpload }
          onAprtIdChange={ handleAprtIdChange }
          onAprtRemove={ handleAprtRemove }
        />
        <Tooltip title={ contentIsHidden ? 'Exibir controles' : 'Esconder controles' } placement="right">
          <button
            className={ styles.contentToggleButton }
            onClick={ toggleContent }
          >
            { contentIsHidden ? <ChevronRightIcon /> : <ChevronLeftIcon /> }
          </button>
        </Tooltip>
        <Tooltip title="Moderação" placement="bottom">
          <button
            className={ classnames(styles.moderationToggleButton, {
              [styles.moderationToggleButtonActive]: showModerationBox
            }) }
            onClick={ toggleModerationBox }
          >
            <WarningIcon />
            { moderationItems && <span>{ _.size(_.filter(moderationItems, { status: 'pendente' })) }</span> }
          </button>
        </Tooltip>
        <Tooltip title="Registro Fundiário" placement="bottom">
          <button
            className={ classnames(styles.landRegistryToggleButton, {
              [styles.landRegistryToggleButtonActive]: !!landRegistryCategory
            }) }
            onClick={ toggleLandRegistryMenu }
          >
            <LandscapeIcon />
          </button>
        </Tooltip>
        <Tooltip title="Processar APP" placement="bottom">
          <button
            className={ classnames(styles.runOverlapButton, {
              [styles.runOverlapButtonActive]: !!processAPPAnchorEl
            }) }
            onClick={ toggleProcessAPPBox }
          >
            <AutorenewIcon />
          </button>
        </Tooltip>
      </div>
      <ModerationBox
        activeItem={ activeModerationItem }
        activeFeature={ activeModerationFeature }
        loadingFeature={ loadingModerationFeature }
        allLayers={ allLayers }
        data={ _.orderBy(moderationItems, 'dataOcorrencia', 'desc') }
        isOpen={ showModerationBox }
        isOnCorner={ contentIsHidden }
        onActiveItemChange={ handleModerationItemChange }
        onClose={ handleModerationBoxClose }
      />
      <LandRegistryBox
        isOpen={ !!landRegistryCategory }
        isOnCorner={ contentIsHidden }
        isDisabled={ isLookingForFeatureSiblings }
        isSearching={ isSearchForCARFeatures }
        category={ landRegistryCategory }
        features={ landRegistryFeatures }
        propertyId={ propertyId }
        aprtId={ aprtId }
        allLayers={ allLayers }
        onCARSearch={ handleLandRegistryCARSearch }
        onClose={ handleLandRegistryBoxClose }
      />
      <PointInfoBox
        baseLayers={ baseLayers }
        data={ pointInfoData }
        layerGroups={ layerGroups }
        propertyId={ propertyId }
        onClose={ resetPointInfo }
      />
      <MapLegend
        baseLayers={ baseLayers }
        layerGroups={ layerGroups }
        propertyId={ propertyId }
      />
      <ShapefileMonitor
        ids={ currentShapefileIds }
        onClose={ handleShapefileMonitorClose }
        onUpdate={ refreshBaseData }
        onAprtUpdate={ refreshAprtData }
      />
      <ProcessAPPBox
        aprtId={ aprtId }
        propertyId={ propertyId }
        anchorEl={ processAPPAnchorEl }
        refreshChecklist={ baseData }
        onUpdate={ refreshBaseData }
      />
      <Popper open={ !!landRegistryAnchorEl } anchorEl={ landRegistryAnchorEl } placement="bottom-start" transition style={ { zIndex: 4000 } }>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={ 350 }>
            <Paper className={ styles.menuPapper }>
              <button className={ styles.menuItem } onClick={ handleLandRegistryAnchorEl.bind(this, 'snci') }>
                <span>SNCI</span>
              </button>
              <button className={ styles.menuItem } onClick={ handleLandRegistryAnchorEl.bind(this, 'sigef') }>
                <span>SIGEF</span>
              </button>
              <button className={ styles.menuItem } onClick={ handleLandRegistryAnchorEl.bind(this, 'car') }>
                <span>CAR</span>
              </button>
            </Paper>
          </Fade>
        )}
      </Popper>
      { isLoadingFarmData &&
        <div className={ styles.contentLoadingWrapper }>
          <CircularProgress />
        </div>
      }
    </BasePage>
  )
}
