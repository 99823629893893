import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import classnames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import ListIcon from '@material-ui/icons/List';

import styles from './MapLegend.module.scss';

import {
  GET_BASE_DATA,
} from './query';

const EXTERNAL_CONTENT_HEIGHT = 207;

export default function MapLegend({
  baseLayers,
  layerGroups,
  propertyId,
}) {
  const contentMaxHeight = window.innerHeight - EXTERNAL_CONTENT_HEIGHT;
  const [isVisible, setIsVisible] = useState(false);
  const [activePanels, setActivePanels] = useState([]);

  const { data: baseData } = useQuery(GET_BASE_DATA);

  const activeLayers = _.get(baseData, 'app.activeLayers');
  const activeBaseLayers = _.get(baseData, 'app.activeBaseLayers');
  const activeLayersIds = _.map(activeLayers, 'id');

  useEffect(() => {
    if (isVisible) {
      setActivePanels(activeLayersIds);
    }
  }, [isVisible]);

  const panelIsOpen = (id) => _.includes(activePanels, id);

  const handlePanelChange = (id) => {
    let dataClone = _.clone(activePanels);

    if (panelIsOpen(id)) {
      dataClone = _.filter(dataClone, (item) => item !== id);
    } else {
      dataClone = _.concat(dataClone, id);
    }

    setActivePanels(dataClone);
  };

  const toggleVisibility = () => setIsVisible(!isVisible);

  const parsedLayers = _(layerGroups)
    .map((layerGroup) => {
      return _.map(layerGroup.options, (layer) => {
        if (_.includes(activeLayersIds, layer.id)) {
          return {
            ...layer,
            groupId: layerGroup.id,
            groupTitle: layerGroup.title,
            groupIcon: layerGroup.icon,
          };
        } else {
          return null;
        }
      });
    })
    .flattenDeep()
    .compact()
    .value();

  const parsedActiveBaseLayers = _.map(activeBaseLayers, (layerId) => {
    const baseLayerData = _.find(baseLayers, { id: layerId });

    return {
      global: true,
      id: layerId,
      label: _.get(baseLayerData, 'label'),
    };
  });

  const layers = [...parsedLayers, ...parsedActiveBaseLayers];

  return (
    <div className={ styles.wrapper }>
      <Tooltip title="Legenda" placement="left">
        <button
          className={ classnames(styles.toggleButton, {
            [styles.toggleButtonActive]: isVisible
          }) }
          onClick={ toggleVisibility }
        >
          <ListIcon />
        </button>
      </Tooltip>
      <Collapse in={ isVisible }>
        <Paper className={ styles.paper }>
          <h2 className={ styles.title }>Legenda</h2>
          <div className={ styles.contentWrapper } style={ { maxHeight: contentMaxHeight } }>
            { _.map(layers, (layer) => {
              return (
                <Accordion
                  square
                  key={ `${ layer.groupId }-${ layer.id }` }
                  expanded={ panelIsOpen(layer.id) }
                  onChange={ handlePanelChange.bind(this, layer.id) }
                >
                  <AccordionSummary>
                    <h3 className={ styles.accordionTitle }>
                      { !layer.global &&
                        <img className={ styles.accordionTitleIcon } src={ layer.groupIcon } />
                      }
                      <span className={ styles.accordionTitleText }>
                        { !layer.global && <small>{ layer.groupTitle }</small> }
                        <b>{ layer.label }</b>
                        </span>
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <img
                      src={
                        !layer.global ?
                        `${ process.env.GEOSERVER_URL }ppc/ows?REQUEST=GetLegendGraphic&VERSION=1.0.0&SERVICE=WMS&FORMAT=image/png&WIDTH=18&HEIGHT=18&LEGEND_OPTIONS=dpi:100&LAYER=${ layer.id }&CQL_FILTER=id_fazenda=${ propertyId }` :
                        `${ process.env.GEOSERVER_URL }ppc/ows?REQUEST=GetLegendGraphic&VERSION=1.0.0&SERVICE=WMS&FORMAT=image/png&WIDTH=18&HEIGHT=18&LEGEND_OPTIONS=dpi:100&LAYER=${ layer.id }`
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }) }
            { _.isEmpty(layers) &&
              <p className={ styles.infoText }>Adicione camadas para visualizar as informações.</p>
            }
          </div>
        </Paper>
      </Collapse>
    </div>
  );
}
