import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import LinearProgress from '@material-ui/core/LinearProgress';

import Dialog from '../../../../../../components/Dialog';
import OptionsList from '../../../../../../components/OptionsList';

import api from '../../../../../../utils/api';

import styles from './SearchFarmDialog.module.scss';
import { GET_FARMS } from './query';
import { clientColetor } from '../../../../../../core/apollo-client/apollo-client';

export default function SearchFarmDialog({
  isOpen,
  onClose = () => {},
}) {
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeFarm, setActiveFarm] = useState(null);
  const [farms, setFarms] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSearch, setCurrentSearch] = useState(null)

  const { data: dataFarms, refetch: refetchFarms } = useQuery(GET_FARMS,
    { 
      variables:{
        page: currentPage,
        where: currentSearch
      },
      client: clientColetor,
      onCompleted: (data) => {

        const parsedData =  _.map(_.get(data, 'farmersList.data'), (item) => {
          return { id: _.get(item, 'id'), label: _.get(item, 'name') };
        });
     
        setPaginationData({
          currentPage: currentPage,
          totalOfPages: _.get(data, 'farmersList.last_page'),
        });
        setFarms(parsedData);
        setActiveFarm(null);
        setLoading(false);
      }
    }
  );

  const loadData = (page = 1, query = '') => {
    setLoading(true);
  
    setCurrentPage(page)
    setCurrentSearch("name LIKE "+ query)
    refetchFarms()

    setLoading(false);
    // console.log(dataFarms)

    // api(`/farms/?page=${ page }&perPage=10&search=${ query }`)
    //   .then((data) => {
    //     const parsedData =  _.map(_.get(data, 'data'), (item) => {
    //       return { id: _.get(item, 'id'), label: _.get(item, 'name') };
    //     });
        
    //     setPaginationData({
    //       currentPage: page,
    //       totalOfPages: _.get(data, 'lastPage'),
    //     });
    //     setFarms(parsedData);
    //     setActiveFarm(null);
    //     setLoading(false);
    //   });
  };

  const debouncedLoadData = useCallback(
    _.debounce((query) => {
      loadData(1, query);
    }, 1000),
    []
  );

  useEffect(() => {
    if (isOpen) {
      loadData();
    }
  }, [isOpen]);

  const handleInputChange = (event) => {
    setInput(event.target.value);
    debouncedLoadData(event.target.value);
  };

  const handlePageChange = (_event, value) => {
    setCurrentPage(value)
    setPaginationData({
      ...paginationData,
      currentPage: value
    });
    setActiveFarm(null);
    loadData(value, input);
  };

  const handleFarmSubmit = () => {
    if (activeFarm) {
      history.push(`/${ activeFarm }`);
      setActiveFarm(null);
      setInput('');
      onClose();
    }
  };

  const handleClose = () => {
    setActiveFarm(null);
    setInput('');
    onClose();
  };

  return (
    <Dialog
      title="Selecionar propriedade"
      isOpen={ isOpen }
      onClose={ handleClose }
      onConfirm={ handleFarmSubmit }
      size="xs"
    >
      <div className={ styles.wrapper }>
        <div className={ styles.searchWrapper }>
          <div className={ styles.searchIconWrapper }>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Pesquise por nome, proprietário, localização..."
            classes={ {
              root: styles.searchInputRoot,
              input: styles.searchInput,
            } }
            value={ input }
            onChange={ handleInputChange }
          />
          { loading &&
            <div className={ styles.loadingWrapper }>
              <LinearProgress />
            </div>
          }
        </div>
        <div className={ styles.listWrapper }>
          <OptionsList
            value={ activeFarm }
            options={ farms }
            onClick={ setActiveFarm }
          />
        </div>
        { paginationData &&
          <div className={ styles.paginationWrapper }>
            <Pagination
              shape="rounded"
              size={ isMobile ? 'small' : 'medium' }
              count={ _.get(paginationData, 'totalOfPages') }
              page={ _.get(paginationData, 'currentPage') }
              onChange={ handlePageChange }
            />
          </div>
        }
      </div>
    </Dialog>
  );
}
