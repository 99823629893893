import React, { useEffect, useRef, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import {
  GeoJSON,
  Map,
  Marker,
  Popup,
  ScaleControl,
  TileLayer,
  WMSTileLayer,
} from 'react-leaflet';
import WMTSTileLayer from 'react-leaflet-wmts'
import { BoxZoomControl } from 'react-leaflet-box-zoom'
import classnames from 'classnames';
import screenfull from 'screenfull';
import moment from 'moment';
import * as turf from '@turf/turf';
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

import MapControls from './components/MapControls';
import MapCustomEvents from './components/MapCustomEvents';
import MapPhotosGallery from './components/MapPhotosGallery';

import baseMaps from '../../../../data/baseMaps';

import styles from './MapCanvas.module.scss';

import {
  GET_BASE_DATA,
} from './query';
import _ from 'lodash';

const mapLayerTypeToIndex = {
  'point': 4,
  'polyline': 3,
  'polygon': 2,
  'raster': 1,
 };

const CONFIG_DEFAULT = {
  lat: -14.392118,
  lng: -56.250000,
  zoom: 4,
  minZoom: 4,
  maxZoom: 17
};

const HEADER_HEIGHT = 76;
const HEADER_MOBILE_HEIGHT = 50;
const FOOTER_HEIGHT = 16;

export default function MapCanvas({
  activeModerationItem,
  allLayers,
  aprtId,
  landRegistryCategory,
  landRegistryFeatures,
  baseLayers,
  flyTo,
  propertyId,
  leftContentIsHidden,
  pointInfoData,
  photosData,
  onPointInfoClick = () => {},
  onLandRegistryPointClick = () => {},
  resetPointInfo = () => {},
}) {
  let mapRef = useRef();
  let zoomControlRef = useRef();
  const client = useApolloClient();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const headerHeight = isMobile ? HEADER_MOBILE_HEIGHT : HEADER_HEIGHT;
  const [activeBaseMap, setActiveBaseMap] = useState(2);
  const [boxZoomIsActive, setBoxZoomIsActive] = useState(false);
  const [pointInfoIsActive, setPointInfoIsActive] = useState(false);
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const [contentHeight, setContentHeight] = useState(window.innerHeight - (headerHeight + FOOTER_HEIGHT));
  const [showPhotosGallery, setShowPhotosGallery] = useState(false);
  const [activePhotos, setActivePhotos] = useState([]);
  const baseMapData = _.find(baseMaps, { id: activeBaseMap });

  const { data: baseData } = useQuery(GET_BASE_DATA);
  const activeLayers = _.get(baseData, 'app.activeLayers');
  const activeBaseLayers = _.get(baseData, 'app.activeBaseLayers');
  const activePhotoGroup = _.get(baseData, 'app.activePhotoGroup');

  const activeModerationLayerData = _.find(allLayers, { idCamada: _.get(activeModerationItem, 'layerId') });

  let flyToBoundsOptions = {
    padding: !isMobile ? [100, 100] : [25, 25]
  };

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params
        }
      }
    });
  };

  function updateContentHeight() {
    if (screenfull.isFullscreen) {
      setContentHeight(window.innerHeight);

      if (mapRef.current) {
        mapRef.current.leafletElement.invalidateSize();
      }
    } else {
      setContentHeight(window.innerHeight - (headerHeight + FOOTER_HEIGHT));
    }
  }

  const debouncedUpdateContentHeight = _.debounce(updateContentHeight, 500);

  useEffect(() => {
    screenfull.on('change', debouncedUpdateContentHeight);

    return () => {
      screenfull.off('change', debouncedUpdateContentHeight);
    };
  }, []);

  useEffect(() => {
    if (_.isArray(flyTo) && mapRef.current) {
      if (_.isArray(_.first(flyTo))) {
        mapRef.current.leafletElement.flyToBounds(flyTo, flyToBoundsOptions);
      } else {
        mapRef.current.leafletElement.flyTo(flyTo, 15);
      }
    }
  }, [flyTo]);

  useEffect(() => {
    if (activePhotoGroup) {
      console.log(photosData)
      const filteredPhotos = _.filter(photosData, (photo) => {
        // return moment(_.get(photo, 'created_at'), 'YYYY-MM-DD').format('DD/MM/YYYY') === activePhotoGroup;
        console.log(_.get(photo, 'image_date'))
        return moment(_.get(photo, 'image_date'), 'YYYY-MM-DD').format('DD/MM/YYYY') === activePhotoGroup;
      });
      const parsedPhotos = _.map(filteredPhotos, (photo) => {
        return {
          lat: Number(_.get(photo, 'latitude')),
          lng: Number(_.get(photo, 'longitude')),
          // photoUrl: _.get(photo, 'path'),
          photoUrl: _.get(photo, 'info_image'),
        };
      });
      const points = _.map(filteredPhotos, (photo) => {
        return [Number(_.get(photo, 'latitude')), Number(_.get(photo, 'longitude'))];
      });
      const multiPoint = turf.multiPoint(points);
      const bbox = turf.bbox(multiPoint);
      const parsedBbox = [[bbox[0], bbox[1]], [bbox[2], bbox[3]]];

      mapRef.current.leafletElement.flyToBounds(parsedBbox, flyToBoundsOptions);
      setActivePhotos(parsedPhotos);
    } else {
      setActivePhotos([]);
    }
  }, [activePhotoGroup]);

  useEffect(() => {
    const headerHeight = isMobile ? HEADER_MOBILE_HEIGHT : HEADER_HEIGHT;
    setContentHeight(window.innerHeight - (headerHeight + FOOTER_HEIGHT));
  }, [isMobile]);

  const handleZoomChange = (positive) => {
    if (!mapRef.current) {
      return;
    }

    if (positive) {
      mapRef.current.leafletElement.zoomIn();
    } else {
      mapRef.current.leafletElement.zoomOut();
    }
  };

  const handleBaseLayersChange = (id) => {
    let dataClone = _.clone(activeBaseLayers);

    if (_.includes(dataClone, id)) {
      dataClone = _.filter(dataClone, (itemId) => itemId !== id);
    } else {
      dataClone = _.concat(dataClone, id);
    }

    updateClientAppState({ activeBaseLayers: dataClone });
  };

  const toggleBoxZoomControl = () => {
    if (!zoomControlRef || !zoomControlRef.current) {
      return;
    }

    if (!boxZoomIsActive) {
      setBoxZoomIsActive(true);
      zoomControlRef.current.start();
    } else {
      setBoxZoomIsActive(false);
      zoomControlRef.current.stop();
    }
  };

  const stopZoomControl = () => {
    if (zoomControlRef.current) {
      zoomControlRef.current.stop();
    }

    setBoxZoomIsActive(false);
  };

  const togglePhotosGallery = () => {
    setShowPhotosGallery(!showPhotosGallery);
  };

  const togglePointInfoControl = () => {
    if (!pointInfoIsActive) {
      setPointInfoIsActive(true);
    } else {
      resetPointInfo();
      setPointInfoIsActive(false);
    }
  };

  const handleMapClick = (event) => {
    const { lat, lng } = event.latlng;
    const { x, y } = event.layerPoint;
    const point1 = { x, y: y - 0.5 };
    const point2 = { x, y: y + 0.5 };

    const parsedPoint1 = mapRef.current.leafletElement.layerPointToLatLng(point1);
    const parsedPoint2 = mapRef.current.leafletElement.layerPointToLatLng(point2);

    const parsedBBox = [
      _.get(parsedPoint2, 'lng'),
      _.get(parsedPoint2, 'lat'),
      _.get(parsedPoint1, 'lng'),
      _.get(parsedPoint1, 'lat'),
    ];

    const bboxString = _.join(parsedBBox, ',');
    const params = {
      bboxString,
      coordinates: [lat, lng]
    };

    if (landRegistryCategory) {
      onLandRegistryPointClick(params);
    } else if (pointInfoIsActive) {
      onPointInfoClick(params);
    }
  };

  const handleCenter = () => {
    // Use 'flyTo' property to center map. While this property is only used with this purpose
    if (flyTo && _.isArray(_.first(flyTo))) {
      mapRef.current.leafletElement.flyToBounds(flyTo, flyToBoundsOptions);
    }
  };

  const handlePopupOpen = () => {
    setPopupIsVisible(true);
  };

  const handlePopupClose = () => {
    setPopupIsVisible(false);
  };

  const renderBaseLayers = () => {
    return _.map(activeBaseLayers, (id, index) => {
      const layerData = _.find(baseLayers, { id });

      if (!layerData) {
        return null;
      }

      return (
        <WMTSTileLayer
          key={ `base-layer-${ layerData.id }` }
          { ...layerData.props }
        />
      );
    })
  };

  const renderActiveLayers = () => {
    return _.map(activeLayers, (layer, index) => {
      const baseLayerData = _.find(allLayers, (item) => {
        return _.includes(layer.id, _.get(item, 'tabela'));
      });

      const layerIndex = baseLayerData ? mapLayerTypeToIndex[baseLayerData.tipo] : 0;

      return (
        <WMSTileLayer
          transparent
          key={ `main-layer-${ layer.id }` }
          zIndex={ 300 + (layerIndex * 10) + index }
          url={ `${ process.env.GEOSERVER_URL }ppc/ows` }
          format="image/png"
          layers={ layer.id }
          cql_filter={ `id_fazenda=${ propertyId } and id_aprt=${ aprtId }` }
        />
      );
    })
  };

  const renderMarkerPhotos = () => {
    return _.map(activePhotos, (item, index) => {
      const lat = _.get(item, 'lat');
      const lng = _.get(item, 'lng');
      const icon = L.divIcon({
        className: 'custom-marker-icon',
        html: `<div class="marker-pin"></div><span style="background-image: url(${ _.get(item, 'photoUrl') })"></span>`,
        iconSize: [40, 56],
        iconAnchor: [20, 56],
        popupAnchor:   [0, -40],
        tooltipAnchor: [16, -28],
      });

      return (
        <Marker
          position={ [lat, lng] }
          icon={ icon }
          key={ `marker-photo-${ index }` }
        >
          <Popup>
            <div className={ styles.photoPopup }>
              <img src={ _.get(item, 'photoUrl') } />
              <div className={ styles.photoPopupContent }>
                <p><span>Coordenadas</span><b>{ lat.toFixed(2) }, { lng.toFixed(2) }</b></p>
              </div>
              <div className={ styles.photoPopupAction }>
                <Button
                  className={ styles.photoPopupActionButton }
                  variant="contained"
                  size="small"
                  onClick={ togglePhotosGallery }
                >
                  <PhotoLibraryIcon />
                  <span>Galeria de fotos</span>
                </Button>
              </div>
            </div>
          </Popup>
        </Marker>
      );
    });
  };

  const renderLandRegistryFeatures = () => {
    if (!_.isEmpty(landRegistryFeatures)) {
      return (
        <GeoJSON
          zIndex={ 400 }
          key={ `land-registry-${ _.map(landRegistryFeatures, 'properties.id') }` }
          data={ landRegistryFeatures }
          style={ {
            color: '#ff7800',
            weight: 3,
            opacity: 0.85
          } }
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Map
      ref={ mapRef }
      center={ [CONFIG_DEFAULT.lat, CONFIG_DEFAULT.lng] }
      zoom={ CONFIG_DEFAULT.zoom }
      minZoom={ CONFIG_DEFAULT.minZoom }
      maxZoom={ CONFIG_DEFAULT.maxZoom }
      style={{ height: contentHeight }}
      zoomControl={ false }
      className={ classnames({ [styles.mapWithPointInfo]: pointInfoIsActive || landRegistryCategory }) }
      onClick={ handleMapClick }
      onpopupopen={ handlePopupOpen }
      onpopupclose={ handlePopupClose }
    >
      <TileLayer
        key="base-map-tile-layer"
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url={ _.get(baseMapData, 'url') }
        zIndex={ 100 }
      />
      <ScaleControl position="bottomright" imperial={ false } />
      <BoxZoomControl
        position="bottomright"
        ref={ zoomControlRef }
        style={ { display: 'none' } }
      />
      <MapCustomEvents
        onMoveStart={ stopZoomControl }
        onZoomStart={ stopZoomControl }
        onEsc={ stopZoomControl }
      />
      <MapControls
        activeBaseMap={ activeBaseMap }
        activeBaseLayers={ activeBaseLayers }
        baseLayers={ baseLayers }
        boxZoomIsActive={ boxZoomIsActive }
        pointInfoIsActive={ pointInfoIsActive }
        isVisible={ (isMobile && leftContentIsHidden && !popupIsVisible) || !isMobile }
        onBaseMapChange={ setActiveBaseMap }
        onBaseLayersChange={ handleBaseLayersChange }
        onBoxZoomToggle={ toggleBoxZoomControl }
        onPointInfoChange={ togglePointInfoControl }
        onCenterMap={ handleCenter }
        onZoomChange={ handleZoomChange }
      />
      { renderBaseLayers() }
      { renderActiveLayers() }
      { renderMarkerPhotos() }
      { renderLandRegistryFeatures() }
      {/* <WMSTileLayer
        transparent
        url="https://production-geoserver.produzindocerto.com.br/geoserver/ppc/ows"
        format="image/png"
        layers="ppc:aprt_limite"
        cql_filter="id_fazenda=1296"
      /> */}
      { activeModerationLayerData &&
        <WMSTileLayer
          transparent
          zIndex={ 500 }
          url={ `${ process.env.GEOSERVER_URL }ppc/ows` }
          format="image/png"
          layers={ `ppc:v_${ _.get(activeModerationLayerData, 'tabela') }` }
          cql_filter={ `id_fazenda=${ propertyId } and id_feicao=${ _.get(activeModerationItem, 'pathId') }` }
        />
      }
      <MapPhotosGallery
        isOpen={ showPhotosGallery }
        data={ activePhotos }
        onClose={ togglePhotosGallery }
      />
      { pointInfoData && <Marker position={ pointInfoData.coordinates } /> }
    </Map>
  );
}
