import { gql } from 'graphql.macro';

export const GET_USER = gql`
  query GetUser{
    user{
      id
      name
      email
      group{
        id
        name
        scope
        dashboard
      }
      group_id
      analyst{
        id
        avatar
        name
        active
        mobile_number
        
      }
    }
  }
`;
