import React, { useState } from 'react';
import classnames from 'classnames';

import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import OptionsList from '../../../../../../components/OptionsList';

import styles from './LayersGroup.module.scss';

export default function LayersGroup({
  icon,
  title,
  activeLayers = [],
  options = [],
  aprtId,
  propertyId,
  onChange = () => {},
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const activeLayersIds = _.map(activeLayers, 'id');
  const filteredActiveOptions = _.filter(options, ({ id }) => _.includes(activeLayersIds, id));

  const handleOptionsChange = (id) => {
    let dataClone = _.clone(activeLayers);
    if (_.includes(activeLayersIds, id)) {
      dataClone = _.filter(dataClone, (item) => item.id !== id);
    } else {
      dataClone = _.concat(dataClone, { __typename: 'LayerItem', id, origin: 'group' });
    }

    onChange(dataClone);
  };

  const toggleOptionsList = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={ styles.wrapper }>
      <div
        className={ classnames(styles.header, {
          [styles.headerIsExpanded]: isExpanded,
          [styles.headerIsActive]: !_.isEmpty(filteredActiveOptions),
        }) }
        onClick={ toggleOptionsList }
      >
        <img className={ styles.headerCategoryIcon } src={ icon } />
        <h3 className={ styles.headerTitle }>{ title }</h3>
        <ExpandMoreIcon className={ styles.headerExpandIcon } />
      </div>
      <Collapse in={ !isExpanded && !_.isEmpty(filteredActiveOptions) }>
        <OptionsList
          isMulti
          size="small"
          className={ classnames(styles.optionsList, styles.activeOptionsList) }
          options={ filteredActiveOptions }
          value={ activeLayersIds }
          onClick={ handleOptionsChange }
          onRemove={ handleOptionsChange }
          customItemStyle={ (option) => {
            const id = _.get(option, 'id');

            if (_.includes(activeLayersIds, id)) {
              const layerData = _.find(activeLayers, { id });

              if (layerData && layerData.origin === 'map') {
                return {
                  borderColor: '#f0932b'
                };
              } else {
                return undefined;
              }
            }
          } }
        />
      </Collapse>
      <Collapse in={ isExpanded }>
        <OptionsList
          isMulti
          expanded={isExpanded}
          type="camada"
          size="small"
          aprtId={aprtId}
          propertyId={propertyId}
          className={ styles.optionsList }
          options={ options }
          value={ activeLayersIds }
          onClick={ handleOptionsChange }
        />
      </Collapse>
    </div>
  );
}
