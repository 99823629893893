import React, { useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useMutation, useQuery } from '@apollo/react-hooks';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';

import toast from '../../../../utils/toast';

import useWindowSize from '../../../../hooks/useWindowSize';

import styles from './LandRegistryBox.module.scss';

import {
  LOAD_REGISTRY_ITEMS,
  UPDATE_REGISTRY_ITEMS,
  ADD_REGISTRY_ITEMS,
  REMOVE_REGISTRY_ITEMS,
} from './query';

const mapCategoryToLabel = {
  snci: 'SNCI',
  sigef: 'SIGEF',
  car: 'CAR',
};

const mapCategoryToTableName = {
  snci: 'limite_snci',
  sigef: 'limite_sigef',
  car: 'limite_car',
};

const mapCategoryToProperty = {
  snci: 'cod_imovel',
  sigef: 'cod_imovel',
  car: 'cod_imovel',
};

export default function LandRegistryBox({
  isOpen,
  isOnCorner,
  isDisabled,
  isSearching,
  category,
  features,
  propertyId,
  aprtId,
  allLayers,
  onCARSearch = () => {},
  onClose = () => {},
}) {
  const size = useWindowSize();
  const [isProcessing, setIsProcessing] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const activeLayerData = _.find(allLayers, { tabela: mapCategoryToTableName[category] });

  const { data: registryItemsData, refetch: refetchRegistryItemsData } = useQuery(LOAD_REGISTRY_ITEMS, {
    variables: {
      idFazenda: propertyId,
      idAprt: aprtId,
      idCamada: _.get(activeLayerData, 'idCamada'),
    },
    skip: !isOpen,
    fetchPolicy: 'no-cache'
  });
  const currentValues = _.map(_.get(registryItemsData, 'aprtRegistroQuery'), 'codImovel');

  const [addRegistryItems] = useMutation(ADD_REGISTRY_ITEMS, {
    onCompleted: (data) => {
      setIsProcessing(false);
      if (_.get(data, 'insertAprtRegistro.status') === true) {
        toast('Registro fundiário atualizado com sucesso!');
      }

      refetchRegistryItemsData({
        variables: {
          idFazenda: propertyId,
          idAprt: aprtId,
          idCamada: _.get(activeLayerData, 'idCamada'),
        }
      });
    },
    onError: () => toast('Ocorreu um erro!', 'error')
  });
  const [removeRegistryItems] = useMutation(REMOVE_REGISTRY_ITEMS, {
    onCompleted: (data) => {
      setIsProcessing(false);
      if (_.get(data, 'deleteAprtRegistro.status') === true) {
        toast('Registro fundiário atualizado com sucesso!');
      }

      refetchRegistryItemsData({
        variables: {
          idFazenda: propertyId,
          idAprt: aprtId,
          idCamada: _.get(activeLayerData, 'idCamada'),
        }
      });
    },
    onError: () => toast('Ocorreu um erro!', 'error')
  });

  const handleUpdate = () => {
    const nextValues = _.map(features, `properties.${ mapCategoryToProperty[category] }`);

    const nextValuesDiff = _.uniq(_.difference(nextValues, currentValues));
    const prevValuesDiff = _.uniq(_.difference(currentValues, nextValues));

    if (!_.isEmpty(nextValuesDiff)) {
      addRegistryItems({
        variables: {
          idFazenda: propertyId,
          idAprt: aprtId,
          idCamada: _.get(activeLayerData, 'idCamada'),
          values: nextValuesDiff
        }
      });
    }

    if (!_.isEmpty(prevValuesDiff)) {
      removeRegistryItems({
        variables: {
          idFazenda: propertyId,
          idAprt: aprtId,
          idCamada: _.get(activeLayerData, 'idCamada'),
          values: prevValuesDiff
        }
      });
    }

    setIsProcessing(true);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchCAR = () => {
    onCARSearch(searchInput);
    setSearchInput('');
  };

  const renderCARSearch = () => {
    if (category !== 'car') {
      return null;
    }

    return (
      <div className={ styles.searchBox }>
        <div className={ styles.searchWrapper }>
          <div className={ styles.searchIconWrapper }>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Pesquise pelo código do CAR..."
            classes={ {
              root: styles.searchInputRoot,
              input: styles.searchInput,
            } }
            value={ searchInput }
            onChange={ handleSearchInputChange }
          />
        </div>
        <div className={ classnames(styles.actionWrapper, styles.actionWrapperSearch) }>
          { (isDisabled || isSearching) &&
            <CircularProgress size={ 20 } color="secondary" />
          }
          <Button
            size="small"
            variant="contained"
            disabled={ isDisabled || isProcessing || isSearching }
            onClick={ handleSearchCAR }
          >
            Pesquisar
          </Button>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return _.map(_.uniqBy(features, 'properties.cod_imovel'), (feature) => {
      const properties = _.get(feature, 'properties');

      return (
        <Accordion
          square
          key={ `feature-${ feature.id }` }
        >
          <AccordionSummary>
            <h3 className={ styles.accordionTitle }>
              <span className={ styles.accordionTitleText }>
                <b>{ _.get(properties, 'cod_imovel') }</b>
              </span>
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={ 0 }>
              { _.map(_.keys(properties), (key) => {
                return (
                  <Grid
                    item
                    xs={ 12 }
                    sm={ key === 'cod_imovel' ? 12 : 6 }
                    key={ `feature-item-${ feature.id }-${ key }` }
                  >
                    <p className={ styles.infoItem }>
                      <span>{ key }</span>
                      <b>{ _.get(properties, key) }</b>
                    </p>
                  </Grid>
                );
              }) }
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <div className={ classnames(styles.wrapper, {
      [styles.wrapperOnCorner]: isOnCorner
    }) }>
      <Fade in={ isOpen }>
        <Paper className={ styles.paper } style={ { height: (size.height || 768) - 200 } }>
          <h2 className={ styles.title }>
            <span className={ styles.titleText }>Registro Fundiário - { mapCategoryToLabel[category] }</span>
            <Tooltip title="Fechar">
              <button className={ styles.titleButton } onClick={ onClose }>
                <CloseIcon />
              </button>
            </Tooltip>
          </h2>
          <div>
            { renderCARSearch() }
            { renderContent() }
          </div>
          <div className={ styles.actionWrapper }>
            { (isDisabled || isProcessing) &&
              <CircularProgress size={ 20 } color="secondary" />
            }
            <Button
              size="small"
              variant="contained"
              color="primary"
              disabled={ isDisabled || isProcessing }
              onClick={ handleUpdate }
            >
              Atualizar
            </Button>
          </div>
        </Paper>
      </Fade>
    </div>
  );
}
