import React, { useState } from 'react';
import _ from 'lodash';
import { DropzoneArea } from 'material-ui-dropzone';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import styles from './ModerationActionForm.module.scss';

const useStyles = makeStyles(theme => createStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
}));

export default function ModerationActionForm({
  activeType,
  onClose = () => {},
  onSubmit = () => {},
}) {
  const [showDropzone, setShowDropzone] = useState(false);
  const [status, setStatus] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const [fileKey, setFileKey] = useState(_.uniqueId('file-'));
  const classes = useStyles();

  const toggleDropzone = () => setShowDropzone(!showDropzone);

  const handleStatusChange = (event) => setStatus(event.target.value);

  const handleDescriptionChange = (event) => setDescription(event.target.value);

  const handleFilesChange = (files) => setFiles(files);

  const handleClose = () => {
    setStatus('');
    setDescription('');
    setFiles([]);
    setFileKey(_.uniqueId('file-'));
    setShowDropzone(false);
    onClose();
  };

  const handleSubmit = () => {
    onSubmit({
      description,
      status,
      files,
    });
    handleClose();
  };

  return (
    <Collapse in={ !!activeType }>
      <div>
        <Grid container spacing={ 2 } className={ styles.row }>
          { activeType === 'status' &&
            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
              <FormControl variant="filled">
                <InputLabel id="property-status-select-label">Status</InputLabel>
                <Select
                  labelId="property-status-select-label"
                  id="property-status-select"
                  value={ status }
                  onChange={ handleStatusChange }
                >
                  <MenuItem value="pendente">Pendente</MenuItem>
                  <MenuItem value="nada consta">Nada consta</MenuItem>
                  <MenuItem value="consta">Consta</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          }
        </Grid>
        <TextField
          label="Comentário"
          variant="filled"
          multiline
          rows={ 4 }
          value={ description }
          onChange={ handleDescriptionChange }
          className={ styles.textInput }
        />
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className={ styles.uploadButton }
          onClick={ toggleDropzone }
          startIcon={ <CloudUploadIcon /> }
        >
          Upload de evidências
        </Button>
        { showDropzone &&
          <DropzoneArea
            key={ fileKey }
            dropzoneClass={ styles.dropzone }
            dropzoneText="Arraste e solte o arquivo aqui ou clique para pesquisar"
            filesLimit={ 1 }
            getFileLimitExceedMessage={ (filesLimit) => `O limite máximo de arquivos foi atingido. É permitido apenas ${ filesLimit } arquivo.` }
            maxFileSize={ 10000000 }
            onChange={ handleFilesChange }
            previewChipProps={ {classes: { root: classes.previewChip } } }
            previewGridProps={ {container: { spacing: 1, direction: 'row', justify: 'center' }} }
            previewText="Arquivos inseridos:"
            showAlerts={ ['error'] }
            showPreviews={ true }
            showPreviewsInDropzone={ false }
            useChipsForPreview
          />
        }
        <div className={ styles.sectionBlockActions }>
          <Button
            size="small"
            variant="contained"
            onClick={ onClose }
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={ handleSubmit }
          >
            Salvar
          </Button>
        </div>
      </div>
    </Collapse>
  );
}
