export default function fetchPropertyCodeFeaturesData(layerId, propertyCode) {
  const geoserverBaseUrl = `${ process.env.GEOSERVER_URL }ppc/wfs?`;
  const wfsParams = [
    'service=WFS',
    'version=2.0.0',
    'request=GetFeature',
    `typeNames=${ layerId }`,
    'outputFormat=application/json',
    'srsName=EPSG:4326',
    `cql_filter=cod_imovel='${ propertyCode }'`,
  ];

  return fetch(`${ geoserverBaseUrl }${ wfsParams.join('&') }`)
    .then((resp) => resp.json());
}
