import React, { Fragment, useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import _ from 'lodash';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import LaunchIcon from '@material-ui/icons/Launch';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import ReplayIcon from '@material-ui/icons/Replay';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

import APRTActionsMenu from './components/APRTActionsMenu';
import LayersGroup from './components/LayersGroup';
import LayersInfoDialog from './components/LayersInfoDialog';
import ExportGeo from './components/exportGeo';

import Dialog from '../../../../components/Dialog';
import GeometryUploadDialog from '../../../../components/GeometryUploadDialog';
import OptionsList from '../../../../components/OptionsList';

import toast from '../../../../utils/toast';

import MAP_LIST from './mapList';

import styles from './LayersBox.module.scss';

import {
  GET_BASE_DATA,
} from './query';

const MAP_PDF_URL = 'https://gerador-mapas.produzindocerto.com.br/map/#TYPE?fazenda=#PROPERTY_ID&aprt=#APRT_ID&municipio=#CITY_CODE&pdf=true';

const BOTTOM_DISTANCE = 200;
const BOTTOM_DISTANCE_MOBILE = 154;

export default function LayersBox({
  aprtList = [],
  propertyId,
  aprtId,
  cityCode,
  layerGroups,
  moderationBoxIsVisible,
  photosData,
  onShapefileUpload = () => {},
  onAprtIdChange = () => {},
  onAprtRemove = () => {},
}) {
  const client = useApolloClient();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isAdmin = localStorage.getItem('profile') === 'admin';
  const bottomDistance = isMobile ? BOTTOM_DISTANCE_MOBILE : BOTTOM_DISTANCE;
  const contentMinHeight = window.innerHeight - (bottomDistance);
  const [modal, setModal] = useState(null);
  const [activeMap, setActiveMap] = useState('');
  const [isProcessingExport, setIsProcessingExport] = useState(false);
  const [exportGeoModal, setExportGeoModal] = useState(false);

  const { data: baseData } = useQuery(GET_BASE_DATA);

  const activePhotoGroup = _.get(baseData, 'app.activePhotoGroup');
  const activeLayers = _.get(baseData, 'app.activeLayers');
  const activeLayersIds = _.map(activeLayers, 'id');
  const activeMapData = _.find(MAP_LIST, { id: activeMap });

  const groupedPhotos = _.groupBy(photosData, (photo) => {
    console.log(photo)
    // return moment(_.get(photo, 'created_at'), 'YYYY-MM-DD').format('DD/MM/YYYY');
    return moment(_.get(photo, 'image_date'), 'YYYY-MM-DD').format('DD/MM/YYYY');
  });

  useEffect(() => {
    if (
      _.isNumber(activeMap) &&
      !_.isEmpty(activeLayersIds) &&
      !_.isEqual(_.sortBy(activeLayersIds), _.sortBy(_.get(activeMapData, 'layers')))
    ) {
      setActiveMap('');
    }
  }, [activeLayersIds]);

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params
        }
      }
    });
  };

  const openModal = (title) => {
    setModal(title);
  };

  const closeModal = () => {
    setModal(null);
  };


  const handleAprtChange = (event) => {
    const value = Number(event.target.value);

    onAprtIdChange(value);
  };

  const handleMapChange = (event) => {
    const value = event.target.value;
    const mapData = _.find(MAP_LIST, { id: value });
    const mapLayers = _.get(mapData, 'layers');
    const conditionalLayers = _.get(mapData, 'conditionalLayers');
    let conditionalLayersSelected = [];

    if (!_.isEmpty(conditionalLayers)) {
      const availableLayers = _(layerGroups)
        .map('options')
        .flatten()
        .map('id')
        .uniq()
        .value();
      conditionalLayersSelected = _(conditionalLayers)
        .map((layerIds) => {
          const selectedLayerId = _.find(layerIds, (layerId) => _.includes(availableLayers, layerId));

          return selectedLayerId;
        })
        .flatten()
        .compact()
        .value();
    }

    const layers = _.concat(mapLayers, conditionalLayersSelected);

    const parsedLayers = _.map(layers, (id) => {
      return { __typename: 'LayerItem', id, origin: 'map' };
    });

    setActiveMap(value);
    updateClientAppState({ activeLayers: parsedLayers });
  };

  const handleLayersChange = (layers) => {
    updateClientAppState({ activeLayers: layers });
  };

  const handleMapReset = () => {
    setActiveMap('');

    const defaultLayer = { __typename: 'LayerItem', id: 'ppc:aprt_limite', origin: 'group' };
    updateClientAppState({ activeLayers: [defaultLayer] });
  };

  const handleLayersReset = () => {
    updateClientAppState({ activeLayers: [] });
  };

  const handleExport = () => {
    const mapData = _.find(MAP_LIST, { id: activeMap });

    if (mapData) {
      const mapType = _.get(mapData, 'id');

      const url = _(MAP_PDF_URL)
        .replace('#TYPE', mapType)
        .replace('#PROPERTY_ID', propertyId)
        .replace('#CITY_CODE', cityCode)
        .replace('#APRT_ID', aprtId);

      toast('Aguarde o processamento do mapa...', 'info');
      setIsProcessingExport(true);
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else {
            toast('Ocorreu um erro.', 'error');
          }
        })
        .then((blob) => {
          const objectURL = URL.createObjectURL(blob);
          const a = document.createElement('a');

          a.href = objectURL;
          a.download = `produzindo-certo-mapa-${ _.kebabCase(_.get(mapData, 'title')) }-${ propertyId }.pdf`;

          document.body.appendChild(a);
          a.click();
          toast('Mapa processado com sucesso!');
          setIsProcessingExport(false);
        });
    } else {
      toast('É necessário escolher um mapa.', 'warning');
    }
  };

  const handlePhotoGroupChange = (value) => {
    if (value === activePhotoGroup) {
      updateClientAppState({ activePhotoGroup: null });
    } else {
      updateClientAppState({ activePhotoGroup: value });
    }
  };

  return (
    <Fade in={ !isMobile || (isMobile && !moderationBoxIsVisible) }>
      <Paper className={ styles.wrapper } style={ { height: contentMinHeight } }>
        <div className={ styles.content }>
          { _.size(aprtList) > 0 &&
            <Fragment>
              <header className={ styles.titleWrapper }>
                <h2 className={ styles.title }>APRT</h2>
                { isAdmin &&
                  <APRTActionsMenu
                    aprtList={ aprtList }
                    aprtId={ aprtId }
                    propertyId={ propertyId }
                    onAprtRemove={ onAprtRemove }
                  />
                }
              </header>
              <Select
                displayEmpty
                value={ aprtId }
                onChange={ handleAprtChange }
                className={ styles.mapSelect }
                variant="outlined"
                placeholder="Escolha uma opção..."
              >
                { _.map(aprtList, (option) => {
                  return (
                    <MenuItem
                      key={ `aprt-option-${ option.idAprt }` }
                      value={ option.idAprt }
                    >
                      { option.idAprt } - { moment(option.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY') } - { option.areaHa.toFixed(2) }ha
                    </MenuItem>
                  );
                }) }
              </Select>
            </Fragment>
          }
          <header className={ styles.titleWrapper }>
            <h2 className={ styles.title }>Mapas</h2>
            <Tooltip title="Resetar" placement="bottom">
              <button className={ styles.titleButton } onClick={ handleMapReset }>
                <ReplayIcon />
              </button>
            </Tooltip>
          </header>
          <Select
            displayEmpty
            value={ activeMap }
            onChange={ handleMapChange }
            className={ styles.mapSelect }
            variant="outlined"
            placeholder="Escolha uma opção..."
          >
            <MenuItem value="" disabled><em>Escolha uma opção...</em></MenuItem>
            { _.map(MAP_LIST, (option) => {
              return (
                <MenuItem key={ `map-option-${ option.id }` } value={ option.id }>{ option.title }</MenuItem>
              );
            }) }
          </Select>
          <header className={ styles.titleWrapper }>
            <h2 className={ styles.title }>Camadas</h2>
            <Tooltip title="Resetar" placement="bottom">
              <button className={ styles.titleButton } onClick={ handleLayersReset }>
                <ReplayIcon />
              </button>
            </Tooltip>
          </header>
          { _.map(layerGroups, (layerGroup) => {
            return (
              <LayersGroup
                key={ `layer-group-${ layerGroup.id }` }
                icon={ layerGroup.icon }
                title={ layerGroup.title }
                aprtId={aprtId}
                propertyId={propertyId}
                activeLayers={ activeLayers }
                options={ layerGroup.options }
                onChange={ handleLayersChange }
              />
            );
          }) }
          <div className={ styles.photosSection }>
            <header className={ styles.titleWrapper }>
              <h2 className={ styles.title }>Fotos</h2>
            </header>
            <OptionsList
              value={ activePhotoGroup }
              options={ _.map(groupedPhotos, (_data, key) => ({ id: key, label: key })) }
              onClick={ handlePhotoGroupChange }
            />
          </div>
        </div>
        <div className={ styles.actionsWrapper }>
          <Tooltip title="Informações das camadas">
            <IconButton onClick={ openModal.bind(this, 'layers-info') }>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Upload de Geometrias">
            <IconButton onClick={ openModal.bind(this, 'geometry-upload') }>
              <CloudUploadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Imprimir">
            <IconButton onClick={ openModal.bind(this, 'Imprimir') }>
              <PrintIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Exportar">
            <IconButton onClick={ handleExport } disabled={ isProcessingExport }>
              { !isProcessingExport && <LaunchIcon /> }
              { isProcessingExport && <CircularProgress color="secondary" size={ 18 } /> }
            </IconButton>
          </Tooltip>
          {/* <Tooltip title="Exportar para GeoJSON">
            <IconButton onClick={ openModal.bind(this, 'Exportar para GeoJSON') }>
            <IconButton onClick={() => setExportGeoModal(true)}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip> */}
        </div>
        <Dialog
          title={ modal }
          isOpen={ !!modal && modal !== 'geometry-upload' && modal !== 'layers-info' }
          onClose={ closeModal }
        />

        <ExportGeo
          isOpen={ exportGeoModal }
          onClose={ () => setExportGeoModal(false) }
          aprt={aprtId}
        />

        <GeometryUploadDialog
          propertyId={ propertyId }
          aprtId={ aprtId }
          isOpen={ modal === 'geometry-upload' }
          onClose={ closeModal }
          onShapefileUpload={ onShapefileUpload }
        />
        <LayersInfoDialog
          isOpen={ modal === 'layers-info' }
          onClose={ closeModal }
          activeLayersIds={ activeLayersIds }
          layerGroups={ layerGroups }
        />
      </Paper>
    </Fade>
  );
}
