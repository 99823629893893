import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from '../Header';

import styles from './BasePage.module.scss';

const HEADER_HEIGHT = 76;
const HEADER_MOBILE_HEIGHT = 50;
const FOOTER_HEIGHT = 16;

function BasePage({
  children,
  onShapefileUpload = () => {},
}) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const headerHeight = isMobile ? HEADER_MOBILE_HEIGHT : HEADER_HEIGHT;
  const contentMinHeight = window.innerHeight - (headerHeight + FOOTER_HEIGHT);

  return (
    <div className={ styles.pageWrapper }>
      <Header onShapefileUpload={ onShapefileUpload } />
      <div id="app-content" className={ styles.contentWrapper } style={ { minHeight: contentMinHeight } }>
        { children }
      </div>
    </div>
  );
}

export default BasePage;
