import React, { Fragment, useEffect, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

import useInterval from '../../../../hooks/useInterval';

import {
  GET_SHAPEFILE_STATUS,
} from './query';

import styles from './ShapefileMonitor.module.scss';

const mapStatusToLabel = {
  'In Process': 'Em processamento',
  'Success': 'Sucesso',
  'Error': 'Erro',
};

const mapAPRTTypeToLabel = {
  'aprt_limite': 'APRT',
  'aprt_class': 'Classe',
  'aprt_drenagem': 'Drenagem',
  'aprt_aceiro': 'Aceiro',
  'aprt_cerca': 'Cerca',
  'aprt_drenagem_pol': 'Drenagem Pol',
  'aprt_erosao': 'Erosão',
  'aprt_lagoa': 'Lagoa',
  'aprt_sede': 'Sede',
  'aprt_nascente': 'Nascente',
  'aprt_erosao_controle': 'Erosão Controle',
};

const OPTIONS = [
  {
    'label': 'Upload e Donwload do arquivo zip',
    'status': 'upload_download'
  },
  {
    'label': 'Inserir Aprt e processar os dados',
    'status': 'insert_aprt'
  },
  {
    'label': 'Atualizar informações da propriedade',
    'status': 'update_info'
  }
]


function ShapefileItem({
  id,
  onUpdate = () => {},
  onAprtUpdate = () => {}
}) {
  const { data: statusData, loading, refetch } = useQuery(GET_SHAPEFILE_STATUS, {
    variables: {
      id
    },
    fetchPolicy: 'no-cache',
  });
  const status = _.get(statusData, 'statusShapeFile.status');
  const response = _.get(statusData, 'statusShapeFile.response');
  const type = _.get(statusData, 'statusShapeFile.aprtTipo');
  const checklistStatus = _.get(statusData, 'statusShapeFile.checklistStatus');
  
  useInterval(() => {
    refetch({
      variables: {
        id
      }
    });
  }, (_.isUndefined(status) || status === 'In Process') ? 10000 : null)

  useEffect(() => {
    if (status === 'Success') {
      onUpdate();
      if(type === 'aprt_limite'){
        onAprtUpdate();
      } 
    }
  }, [status]);

  return (
    <div className={ styles.itemWrapper }>
      { loading && <CircularProgress size={ 20 } color="secondary" /> }
      <Grid container spacing={ 1 }>
        <Grid item xs={ 12 } sm={ 6 }>
          <p className={ styles.infoItem }>
            <span>Tipo</span>
            <b>{ mapAPRTTypeToLabel[type] }</b>
          </p>
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <p className={ styles.infoItem }>
            <span>Status</span>
            <b>{ mapStatusToLabel[status] }</b>
          </p>
        </Grid>
        <Grid item xs={ 12 } sm={ 12 }>
          <ul className={ styles.categoryList }>
            { _.map(OPTIONS, (option) => {
              return (
                <li key={ `checklist-${ _.kebabCase(option) }` }>
                  { 
                    checklistStatus ?
                        (_.includes(checklistStatus, option.status)?
                          <CheckCircleIcon className={ styles.iconActive } /> :
                              _.isUndefined(status) || status === 'In Process' ?
                              <CheckCircleOutlinedIcon /> :
                              <CheckCircleIcon className={ styles.iconError }/>                                
                        )
                    :
                    <CheckCircleOutlinedIcon />
                  }
                  <span>{ option.label }</span>
                </li>
              );
            }) }
          </ul>
        </Grid>
        { response &&
          <Grid item xs={ 12 } sm={ 12 }>
            <p className={ styles.infoItem }>
              <span>Resultado</span>
              <b>{ response }</b>
            </p>
          </Grid>
        }
      </Grid>
    </div>
  );
}

export default function ShapefileMonitor({
  ids,
  onClose = () => {},
  onUpdate = () => {},
  onAprtUpdate = () => {}
}) {
  return (
    <Fade in={ !_.isEmpty(ids) }>
      <Paper className={ styles.wrapper }>
        <h2>Tarefas em processamento</h2>
        <IconButton
          size="small"
          className={ styles.closeButton }
          onClick={ onClose }
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Paper className={ styles.listResultShapes } elevation={0}>
          { _.map(ids, (id) => {
            return (
              <ShapefileItem
                key={ `shapefile-${ id }` }
                id={ id }
                onUpdate={ onUpdate }
                onAprtUpdate={ onAprtUpdate }
              />
            );
          }) }
        </Paper>
      </Paper>
    </Fade>
  );
}
