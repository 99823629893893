import React, { useState } from 'react';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

import { DatePicker } from 'formik-material-ui-pickers';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import Dialog from '../Dialog';
import FormikFileInput from '../FormikFileInput';

import toast from '../../utils/toast';

import styles from './GeometryUploadDialog.module.scss';

const mapTypes = [
  { id: 'aprt_limite', label: 'APRT' },
  { id: 'aprt_class', label: 'Classe' },
  { id: 'aprt_drenagem', label: 'Drenagem' },
  { id: 'aprt_aceiro', label: 'Aceiro' },
  // { id: 'aprt_cerca', label: 'Cerca' },
  { id: 'aprt_analise_agua', label: 'Análise da Água'},
  { id: 'aprt_drenagem_pol', label: 'Drenagem Pol' },
  { id: 'aprt_erosao', label: 'Erosão' },
  { id: 'aprt_lagoa', label: 'Lagoa' },
  // { id: 'aprt_sede', label: 'Sede' },
  { id: 'aprt_nascente', label: 'Nascente' },
  // { id: 'aprt_erosao_controle', label: 'Erosão Controle' },
  { id: 'aprt_vereda', label: 'Vereda' },
];

const mapTypesGlobal = [
  { id: 'aprt_class_global', label: 'Class PPC Global' },
  { id: 'aprt_drenagem_global', label: 'Drenagem Global' },
  { id: 'aprt_drenagem_pol_global', label: 'Drenagem pol Global' },
  { id: 'aprt_nascente_global', label: 'Nascente Global' },
  { id: 'aprt_lagoa_global', label: 'Lagoa Global' },
  { id: 'aprt_vereda_global', label: 'Vereda Global' },
];

const mapMapTypeToTypes = {
  'aprt_drenagem': [
    { id: 'perene', label: 'Perene' },
    { id: 'intermitente', label: 'Intermitente' },
    { id: 'efemero', label: 'Efêmero' },
  ],
  'aprt_drenagem_pol': [
    { id: 'perene', label: 'Perene' },
    { id: 'intermitente', label: 'Intermitente' },
    { id: 'efemero', label: 'Efêmero' },
  ]
};

const mapMapTypeToDownloadLink = {
  Lagoas: 'https://homologacao.produzindocerto.com.br/shapes/gis_lagoa.zip',
  Nascentes: 'https://homologacao.produzindocerto.com.br/shapes/gis_nascente.zip',
  Rios: 'https://homologacao.produzindocerto.com.br/shapes/gis_rio.zip',
};

export default function GeometryUploadDialog({
  propertyId,
  aprtId,
  isOpen,
  global = false,
  onClose = () => {},
  onShapefileUpload = () => {},
}) {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = (params) => {
    const {
      mapType,
      type,
      picture,
      date,
      file,
    } = params;

    let formData  = new FormData();

    formData.append('shapefile[aprt_tipo]', mapType);
    formData.append('shapefile[file]', file);

    if (!global) {
      formData.append('shapefile[id_fazenda]', propertyId);
      formData.append('shapefile[id_aprt]', aprtId || 1);
    }

    if (type) {
      formData.append('shapefile[tipo]', type);
    }

    if (picture) {
      formData.append('shapefile[picture]', picture);
    }

    if (date) {
      formData.append('shapefile[data]', date.format('YYYY-MM-DD'));
    }

    setIsProcessing(true);
    fetch(`/shapefile`, {
      method: 'POST',
      // TODO: Add Bearer token when API needs it
      headers: {
        'Authorization': `Bearer ${ localStorage.getItem('token') }`
      },
      body: formData
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (_.get(data, 'response') === 'Upload em processamento!') {
          onShapefileUpload(_.get(data, 'id'));
          toast('Upload em processamento...');
          onClose();
        } else {
          toast('Ocorreu um erro.', 'error');
        }
        setIsProcessing(false);
      });
  };

  const renderDownloadLink = () => {
    if (!_.includes(['Lagoas', 'Nascentes', 'Rios'], mapType)) {
      return null;
    }

    const link = mapMapTypeToDownloadLink[mapType];

    return (
      <p className={ styles.infoText }>Faça o <a href={ link } target="_blank">download do arquivo modelo</a> para o envio dos shapes de mapas na plataforma.</p>
    );
  };

  const renderTypeField = (values) => {
    return null;

    const { mapType } = values || {};
    const types = mapMapTypeToTypes[mapType];

    if (!types) {
      return null;
    }

    return (
      <Field
        select
        className={ styles.formControl }
        variant="filled"
        label="Categoria"
        component={ TextField }
        name="type"
      >
        { _.map(types, ({ id, label }) => {
          return (
            <MenuItem key={ `type-${ id }` } value={ id }>{ label }</MenuItem>
          );
        }) }
      </Field>
    );
  };

  const renderPictureField = (values) => {
    const { mapType } = values || {};

    if (!_.includes(['aprt_erosao', 'aprt_erosao_controle'], mapType)) {
      return null;
    }

    return (
      <Field
        className={ styles.formControl }
        component={ TextField }
        name="picture"
        label="URL da Imagem"
        variant="filled"
      />
    );
  };

  const renderDateField = (values) => {
    return null;

    const { mapType } = values || {};

    if (!_.includes(['aprt_class'], mapType)) {
      return null;
    }

    return (
      <Field
        className={ styles.formControl }
        component={ DatePicker }
        name="date"
        label="Data"
        autoOk={ true }
        format="DD/MM/YYYY"
        variant="inline"
        inputVariant="filled"
      />
    );
  };

  return (
    <Dialog
      hideActions
      isOpen={ isOpen }
      title="Upload de Geometrias"
      onClose={ onClose }
      size="xs"
      className={ styles.dialogWrapper }
      onConfirm={ handleSubmit }
    >
      <Formik
        initialValues={ {
          mapType: '',
          type: '',
          picture: '',
          date: null,
          file: null,
        } }
        validationSchema={ yup.object({
          mapType: yup
            .string()
            .required('Este campo é obrigatório.'),
          // type: yup
          //   .string()
          //   .when('mapType', {
          //     is: (value) => value === 'aprt_drenagem' || value === 'aprt_drenagem_pol',
          //     then: yup
          //       .string()
          //       .required('Este campo é obrigatório.'),
          //   }),
          picture: yup
            .string(),
          // date: yup
          //   .string()
          //   .nullable()
          //   .when('mapType', {
          //     is: (value) => value === 'aprt_class',
          //     then: yup
          //       .string()
          //       .nullable()
          //       .required('Este campo é obrigatório.'),
          //   }),
          file: yup
            .mixed()
            .required('Este campo é obrigatório.')
        }) }
        onSubmit={ handleSubmit }
      >
        { ({ resetForm, submitForm, values, isSubmitting }) => (
          <Form>
            <Field
              select
              fullWidth
              variant="filled"
              label="Tipo de Mapa"
              component={ TextField }
              name="mapType"
            >
              { _.map(!global ? mapTypes : mapTypesGlobal, ({ id, label }) => {
                const isDisabled = !aprtId && id !== 'aprt_limite' && !global;

                return (
                  <MenuItem
                    key={ `map-type-${ id }` }
                    disabled={ isDisabled }
                    value={ id }
                  >
                    { label }
                  </MenuItem>
                );
              }) }
            </Field>
            { renderTypeField(values) }
            { renderPictureField(values) }
            { renderDateField(values) }
            <div className={ styles.formControl }>
              <Field
                className={ styles.formControl }
                component={ FormikFileInput }
                name="file"
                label="Arquivo"
                accept=".zip"
              />
            </div>
            { isProcessing &&
              <div className={ styles.loadingActionsWrapper }>
                <CircularProgress size={ 20 } color="secondary" />
              </div>
            }
            { !isProcessing &&
              <div className={ styles.actionsWrapper }>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Confirmar
                </Button>
              </div>
            }
          </Form>
        ) }
      </Formik>
    </Dialog>
  );
}
