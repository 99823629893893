import { createMuiTheme } from '@material-ui/core/styles';

import {
  greys,
  primaryColor,
  primaryColorLight,
  primaryColorDark,
  primaryColorContrastText,
  secondaryColor,
  secondaryColorLight,
  secondaryColorDark,
  secondaryColorContrastText
} from './variables';

export default createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiFormControl: {
      fullWidth: true
    },
    MuiInputLabel: {
    },
    MuiInput: {
      disableUnderline: true
    },
    MuiSelect: {
      disableUnderline: true
    },
    MuiFilledInput: {
      disableUnderline: true
    }
  },

  overrides: {
    MuiAccordion: {
      root: {
        borderRadius: 0,
        '&:first-child': {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        },
        '&:last-child': {
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        },
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:last-child .MuiAccordionSummary-root': {
          borderBottom: 0,
        },
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: 'auto',
        },
      },
      expanded: {},
    },
    MuiAccordionSummary: {
      root: {
        padding: '0 10px',
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 'auto',
        '&$expanded': {
          minHeight: 'auto',
          borderBottom: '1px solid rgba(0, 0, 0, .125) !important',
        },
      },
      content: {
        margin: '2px 0',
        '&$expanded': {
          margin: '2px 0',
        },
      },
      expanded: {},
    },
    MuiAccordionDetails: {
      root: {
        padding: 8,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '12px 14px'
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: 0,
        '&[class*="MuiOutlinedInput-root"]': {
          padding: '0 9px',
          lineHeight: '18px',
          borderRadius: 20
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#c0392b !important'
        }
      },
      noOptions: {
        fontSize: 14
      },
      option: {
        fontSize: 14
      },
      popper: {
        zIndex: 9999
      },
    },
    MuiIconButton: {
      root: {
        color: '#555'
      },
    },
    MuiButton: {
      root: {
      },
      contained: {
        padding: '5px 30px',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none'
        },
        '&:focus': {
          boxShadow: 'none'
        }
      },
      containedSizeLarge: {
        padding: '6px 40px'
      },
      containedSizeSmall: {
        padding: '4px 20px'
      },
      text: {
        padding: '6px 16px'
      }
    },
    MuiDialog: {
      root: {
        zIndex: '5000 !important'
      }
    },
    MuiPopover: {
      root: {
        zIndex: '5000 !important'
      }
    },
    MuiFab: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiFormControl: {
      root: {
        '&.input-label-bg-white': {
          '& .MuiInputLabel-shrink': {
            backgroundColor: 'white',
          },
        }
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
        fontWeight: 500
      },
      outlined: {
        transform: 'translate(14px, 12px) scale(1)',
        '&.MuiInputLabel-shrink': {
          padding: '0 6px',
          transform: 'translate(8px, -6px) scale(0.9)'
        }
      },
    },
    MuiInput: {
      root: {
      },
      formControl: {
        'label + &': {
          marginTop: 20
        }
      }
    },
    MuiInputBase: {
      input: {
        padding: 0,
        fontSize: 14
      }
    },
    MuiMenuItem: {
      root: {
        minHeight: 40,
        fontSize: 14
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
        backgroundColor: '#444',
      },
      popper: {
        zIndex: 9999
      }
    },
    MuiFormLabel: {
      root: {
        color: '#555'
      },
    },
    MuiPaper: {
      root: {
        padding: 0,
        borderRadius: 8,
        backgroundColor: 'white',
        boxShadow: '0 4px 4px rgba(0, 0, 0, .2)',
      },
      rounded: {
        borderRadius: 8,
      },
      elevation1: {
        boxShadow: '0 4px 4px rgba(0, 0, 0, .2)'
      }
    },
    MuiSnackbar: {
      root: {
        zIndex: 9999
      }
    }
  },

  palette: {
    background: {
      default: greys[100]
    },
    grey: greys,
    primary: {
      dark: primaryColorDark,
      light: primaryColorLight,
      main: primaryColor,
      contrastText: primaryColorContrastText
    },
    secondary: {
      dark: secondaryColorDark,
      light: secondaryColorLight,
      main: secondaryColor,
      contrastText: secondaryColorContrastText
    },
    text: {
      primary: greys[800],
      secondary: greys[500]
    }
  },

  typography: {
    fontFamily: '"Open-Sans", sans-serif'
  }
});
