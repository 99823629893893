import _ from 'lodash';

const API_URL = new URL('/proxy', process.env.APP_URL);

const LOGIN_URL = new URL('/#/auth/signin', process.env.ALIANCA_MAIN_URI);

const TOKEN = localStorage.getItem('token') || null;

export default function api(
  endpoint,
  originConfig,
  customHeaders = { 'Content-Type': 'application/json' }
) {
  let config = _.extend({}, originConfig);
  let url = API_URL;

  if (TOKEN) {
    config.headers = _.extend(
      { 'Authorization': `Bearer ${ TOKEN }` },
      customHeaders
    );
  } else {
    config.headers = customHeaders;
  }

  return fetch(url + endpoint, config)
    .then(response => {
      if (response.ok) {
        const contentType = response.headers.get('content-type');

        if (_.includes(contentType, 'xls') || _.includes(contentType, 'pdf')) {
          return response.blob();
        } else if (_.includes(contentType, 'json')) {
          return response.json();
        } else if (_.includes(contentType, 'text')) {
          return response.text();
        }

        return response;
      } else {
        return Promise.reject({
          status: response.status,
          statusText: response.statusText,
          json: response.json ? response.json() : null
        });
      }
    })
    .then(json => json)
    .catch(error => {
      if (error.status === 401) {
        window.location.href = LOGIN_URL;
      }

      if (error.json) {
        error.json
          .then((errorResponse) => {
            if (errorResponse.message) {
              // toast(errorResponse.message, 'error');
            } else {
              // toast('Ocorreu um erro.', 'error');
            }
          })
      }

      /* eslint-disable */
      console.log(JSON.stringify(error, undefined, 2));
      /* eslint-disable */

      return Promise.reject(error);
    });
}
