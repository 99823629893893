import React from 'react'

import { ApolloProvider } from 'react-apollo'
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pt-br';

import client from '../apollo-client'
import Routes from '../Routes'
import theme from '../themes/main'

import ToastContainer from '../../components/ToastContainer';

moment.locale('pt-br')

class Root extends React.Component {
  render() {
    return (
      <ApolloProvider client={ client }>
        <ThemeProvider theme={ theme }>
          <StylesProvider injectFirst>
            <MuiPickersUtilsProvider utils={ MomentUtils }>
              <Routes />
              <ToastContainer />
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </ApolloProvider>
    )
  }
}

export default Root
