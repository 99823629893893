import {
  ApolloClient
} from 'apollo-client'
import {
  InMemoryCache
} from 'apollo-cache-inmemory'
import {
  createUploadLink
} from 'apollo-upload-client'
import {
  onError
} from 'apollo-link-error'
import {
  setContext
} from 'apollo-link-context'
import {
  ApolloLink
} from 'apollo-link'
import { HttpLink } from 'apollo-link-http';
import _ from 'lodash'

import {
  MutationResolver,
  QueryResolver
} from './local-resolvers'
import {
  GET_USER
} from '../Routes/queries'

const cache = new InMemoryCache()

cache.writeData({
  data: {
    app: {
      __typename: 'AppState',
      activeLayers: [],
      activeBaseLayers: [],
      activePhotoGroup: null,
    },
    toast: {
      __typename: 'ToastState',
      content: null,
      severity: null,
      visible: false
    }
  }
});

const logErrors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        '[GraphQL error]: Message: ',
        message,
        ', Location: ',
        locations,
        ', Path: ',
        path
      )
    )
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const uploadLink = createUploadLink({
  uri: '/api/graphql'
})

const withToken = setContext((_, previousContext) => {
  let token = localStorage.getItem('token')

  return {
    ...previousContext,
    headers: {
      ...(previousContext && previousContext.headers),
      'Authorization': token ? `Bearer ${token}` : ''
    }
  }
})

const resetTokenOnUnauthorized = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors && _.some(graphQLErrors, (e) => e.extensions && e.extensions.forbidden)) {
    if (operation.operationName !== _.get(GET_USER, 'definitions[0].name.value')) {
      // So we don't reset the store when it's not necessary
      if (localStorage.getItem('token')) {
        client.resetStore()
      }
    }
    localStorage.removeItem('token')
  }
})

const link = new HttpLink({
  uri: '/graphql',
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from(_.compact([
    withToken,
    resetTokenOnUnauthorized,
    process.env.NODE_ENV === 'development' ? logErrors : null,
    // uploadLink,
    link
  ])),
  resolvers: { Query: QueryResolver, Mutation: MutationResolver }
})

const linkColetor = new HttpLink({
  uri: process.env.API_COLETOR,
});

// const withTokenColetor = setContext((_, previousContext) => {
//   let token = localStorage.getItem('token')

//   return {
//     ...previousContext,
//     headers: {
//       ...(previousContext && previousContext.headers),
//       'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2NvbGV0b3IucHJvZHV6aW5kb2NlcnRvLmNvbS5ici9ncmFwaHFsIiwiaWF0IjoxNzEwODc0NDY2LCJleHAiOjE3MTA5NjA4NjYsIm5iZiI6MTcxMDg3NDQ2NiwianRpIjoibnVFYTZGcXFCNlU5N2FWNyIsInN1YiI6IjU2NjUiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3IiwidWlkIjo1NjY1fQ.ao5Ju9h9dbt5qNYaHVeolhdEXyouG4R8JsTsptRrvos`
//     }
//   }
// })


export const clientColetor = new ApolloClient({
  cache,
  link: ApolloLink.from(_.compact([
    withToken,
    resetTokenOnUnauthorized,
    process.env.NODE_ENV === 'development' ? logErrors : null,
    // uploadLink,
    linkColetor
  ])),
  resolvers: { Query: QueryResolver, Mutation: MutationResolver }
})

export default client
