import React, { useState } from 'react';
import _ from 'lodash';

import { DatePicker } from '@material-ui/pickers';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import styles from './ModerationListFilters.module.scss';

const initialState = {
  category: '',
  status: 'pendente',
  date: null,
};

const blankState = {
  category: '',
  status: '',
  date: null,
};

export default function ModerationListFilters({
  categoriesList,
  statusList,
  onChange,
}) {
  const [state, setState] = useState(initialState);

  const handleStateChange = (paramKey, paramValue) => {
    const nextState = {
      ...state,
      [paramKey]: paramValue
    };
    setState(nextState);
    onChange(nextState);
  };

  const handleSelectChange = (paramKey, event) => {
    handleStateChange(paramKey, event.target.value);
  };

  const handleDateChange = (value) => {
    handleStateChange('date', value);
  };

  const handleReset = () => {
    setState(blankState);
    onChange(blankState);
  };

  const hasFilter = state.category !== '' || state.status !== '' || state.date !== null;

  return (
    <div>
      <div className={ styles.wrapper }>
        <FormControl size="small" variant="outlined" className="input-label-bg-white">
          <InputLabel id="moderation-category-select-label">Categoria</InputLabel>
          <Select
            labelId="moderation-category-select-label"
            id="moderation-category-select"
            value={ state.category }
            onChange={ handleSelectChange.bind(this, 'category') }
          >
            { _.map(categoriesList, (category) => {
              return (
                <MenuItem key={ `category-${ category }` } value={ category }>{ category }</MenuItem>
              );
            }) }
          </Select>
        </FormControl>
        <FormControl size="small" variant="outlined" className="input-label-bg-white">
          <InputLabel id="moderation-status-select-label">Status</InputLabel>
          <Select
            labelId="moderation-status-select-label"
            id="moderation-status-select"
            value={ state.status }
            onChange={ handleSelectChange.bind(this, 'status') }
          >
            { _.map(statusList, (status) => {
              return (
                <MenuItem key={ `status-${ status }` } value={ status }>{ _.capitalize(status) }</MenuItem>
              );
            }) }
          </Select>
        </FormControl>
        <FormControl size="small" variant="outlined" className="input-label-bg-white">
          <DatePicker
            autoOk
            fullWidth
            disableToolbar
            className={ styles.input }
            format="DD/MM/YYYY"
            variant="inline"
            inputVariant="outlined"
            label="Data"
            value={ state.date }
            onChange={ handleDateChange }
          />
        </FormControl>
      </div>
      { hasFilter &&
        <Button
          size="small"
          variant="contained"
          onClick={ handleReset }
          startIcon={ <CloseIcon /> }
          className={ styles.resetButton }
        >
          Resetar
        </Button>
      }
    </div>
  );
}
