export default function fetchPropertyLayerPathData(layerId, propertyId, pathId) {
  const geoserverBaseUrl = `${ process.env.GEOSERVER_URL }ppc/wfs?`;
  const wfsParams = [
    'service=WFS',
    'version=1.0.0',
    'request=GetFeature',
    `typeName=${ layerId }`,
    'outputFormat=application/json',
    `cql_filter=id_fazenda=${ propertyId } and id_feicao=${ pathId }`,
  ];

  return fetch(`${ geoserverBaseUrl }${ wfsParams.join('&') }`)
    .then((resp) => resp.json());
}
